import AbstractBulkAction from "../abstract_bulk_action.js";

const TPL = `
<tr>
    <td colspan="2">
        <span class="bx bx-trash"></span>
    
        删除匹配的笔记
    </td>
    <td class="button-column">
        <div class="dropdown help-dropdown">
            <span class="bx bx-help-circle icon-action" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></span>
            <div class="dropdown-menu dropdown-menu-right p-4">
                <p>这会删除匹配的笔记</p>
                 
                <p>删除后,可以取消删除<span class="bx bx-history"></span>最近的笔记对话框.</p>
                
                <p>要永久删除笔记,您可以在删除后转到选项 -> 其他,然后单击"立即删除已删除的笔记"按钮.</p>
            </div>
        </div>
        
        <span class="bx bx-x icon-action action-conf-del"></span>
    </td>
</tr>`;

export default class DeleteNoteBulkAction extends AbstractBulkAction {
    static get actionName() { return "deleteNote"; }
    static get actionTitle() { return "删除笔记"; }

    doRender() {
        return $(TPL);
    }
}
