import server from "../../../services/server.js";
import protectedSessionHolder from "../../../services/protected_session_holder.js";
import toastService from "../../../services/toast.js";
import OptionsWidget from "./options_widget.js";

const TPL = `
<div class="options-section">
    <h4 class="password-heading"></h4>
    
    <div class="alert alert-warning" role="alert" style="font-weight: bold; color: red !important;">
      请一定要记住你的新密码. 密码被用来登录和加密笔记. 如果你忘记了密码, 所有受保护的笔记将永远丢失. 
      如果你忘记了密码, <a class="reset-password-button" href="javascript:">点击这里来重置它</a>.
    </div>
    
    <form class="change-password-form">
        <div class="old-password-form-group form-group">
            <label>旧密码</label>
            <input class="old-password form-control" type="password">
        </div>
    
        <div class="form-group">
            <label>新密码</label>
            <input class="new-password1 form-control" type="password">
        </div>
    
        <div class="form-group">
            <label>新密码确认</label>
            <input class="new-password2 form-control" type="password">
        </div>
    
        <button class="save-password-button btn btn-primary">修改密码</button>
    </form>
</div>

<div class="options-section">
    <h4>保护会话超时</h4>

    <p>保护会话超时是从浏览器内存里清理保护会话的间隔, 这个时间从最后一次编辑保护的笔记开始计算. 详见 <a href="https://github.com/zadam/trilium/wiki/Protected-notes" class="external">wiki</a> 以获取更多信息</p>

    <div class="form-group">
        <label>保护会话超时(单位:秒)</label>
        <input class="protected-session-timeout-in-seconds form-control options-number-input" type="number" min="60">
    </div>
</div>`;

export default class PasswordOptions extends OptionsWidget {
    doRender() {
        this.$widget = $(TPL);

        this.$passwordHeading = this.$widget.find(".password-heading");
        this.$changePasswordForm = this.$widget.find(".change-password-form");
        this.$oldPassword = this.$widget.find(".old-password");
        this.$newPassword1 = this.$widget.find(".new-password1");
        this.$newPassword2 = this.$widget.find(".new-password2");
        this.$savePasswordButton = this.$widget.find(".save-password-button");
        this.$resetPasswordButton = this.$widget.find(".reset-password-button");

        this.$resetPasswordButton.on("click", async () => {
            if (confirm("重置密码会永远丢失所有受保护的笔记. 确定要重置密码吗?")) {
                await server.post("password/reset?really=yesIReallyWantToResetPasswordAndLoseAccessToMyProtectedNotes");

                const options = await server.get('options');
                this.optionsLoaded(options);

                toastService.showError("Password has been reset. Please set new password");
            }
        });

        this.$changePasswordForm.on('submit', () => this.save());

        this.$protectedSessionTimeout = this.$widget.find(".protected-session-timeout-in-seconds");
        this.$protectedSessionTimeout.on('change', () =>
            this.updateOption('protectedSessionTimeout', this.$protectedSessionTimeout.val()));
    }

    optionsLoaded(options) {
        const isPasswordSet = options.isPasswordSet === 'true';

        this.$widget.find(".old-password-form-group").toggle(isPasswordSet);
        this.$passwordHeading.text(isPasswordSet ? '修改密码' : '设置密码');
        this.$savePasswordButton.text(isPasswordSet ? '修改密码' : '设置密码');
        this.$protectedSessionTimeout.val(options.protectedSessionTimeout);
    }

    save() {
        const oldPassword = this.$oldPassword.val();
        const newPassword1 = this.$newPassword1.val();
        const newPassword2 = this.$newPassword2.val();

        this.$oldPassword.val('');
        this.$newPassword1.val('');
        this.$newPassword2.val('');

        if (newPassword1 !== newPassword2) {
            toastService.showError("New passwords are not the same.");
            return false;
        }

        server.post('password/change', {
            'current_password': oldPassword,
            'new_password': newPassword1
        }).then(result => {
            if (result.success) {
                toastService.showError("Password has been changed. Trilium will be reloaded after you press OK.");

                // password changed so current protected session is invalid and needs to be cleared
                protectedSessionHolder.resetProtectedSession();
            }
            else {
                toastService.showError(result.message);
            }
        });

        return false;
    }
}
