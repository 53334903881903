import OptionsWidget from "../options_widget.js";
import server from "../../../../services/server.js";
import toastService from "../../../../services/toast.js";

const TPL = `
<div class="options-section">
    <h4>笔记清理超时</h4>

    <p>被删除的笔记(属性, 修改版本等)只是被标记为"删除", 是可以从"最近笔记"还原的. 过了一段时间这些笔记会被"清理", 被清理的笔记就无法还原了. 这个设置可以用来调整从"删除"到被"清理"的时间间隔</p>

    <div class="form-group">
        <label>超过X秒后清理笔记</label>
        <input class="erase-entities-after-time-in-seconds form-control options-number-input" type="number" min="0">
    </div>
    
    <p>您也可以手动触发清理（而不考虑上述定义的超时时间）:</p>
    
    <button class="erase-deleted-notes-now-button btn">清理已删除的笔记</button>
</div>`;

export default class NoteErasureTimeoutOptions extends OptionsWidget {
    doRender() {
        this.$widget = $(TPL);
        this.$eraseEntitiesAfterTimeInSeconds = this.$widget.find(".erase-entities-after-time-in-seconds");
        this.$eraseEntitiesAfterTimeInSeconds.on('change', () => this.updateOption('eraseEntitiesAfterTimeInSeconds', this.$eraseEntitiesAfterTimeInSeconds.val()));

        this.$eraseDeletedNotesButton = this.$widget.find(".erase-deleted-notes-now-button");
        this.$eraseDeletedNotesButton.on('click', () => {
            server.post('notes/erase-deleted-notes-now').then(() => {
                toastService.showMessage("删除的笔记已被清理.");
            });
        });
    }

    async optionsLoaded(options) {
        this.$eraseEntitiesAfterTimeInSeconds.val(options.eraseEntitiesAfterTimeInSeconds);
    }
}
