import protectedSessionService from "../services/protected_session.js";
import SwitchWidget from "./switch.js";

export default class ProtectedNoteSwitchWidget extends SwitchWidget {
    doRender() {
        super.doRender();

        this.$switchOnName.text("保护笔记");
        this.$switchOnButton.attr("title", "笔记未受保护，点击以进行保护");

        this.$switchOffName.text("取消保护笔记");
        this.$switchOffButton.attr("title", "笔记已受保护，点击以取消保护");
    }

    switchOn() {
        protectedSessionService.protectNote(this.noteId, true, false);
    }

    switchOff() {
        protectedSessionService.protectNote(this.noteId, false, false)
    }

    async refreshWithNote(note) {
        this.$switchOn.toggle(!note.isProtected);
        this.$switchOff.toggle(!!note.isProtected);
    }

    entitiesReloadedEvent({loadResults}) {
        if (loadResults.isNoteReloaded(this.noteId)) {
            this.refresh();
        }
    }
}
