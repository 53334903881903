import OptionsWidget from "../options_widget.js";
import toastService from "../../../../services/toast.js";
import server from "../../../../services/server.js";

const TPL = `
<div class="options-section">
    <h4>数据库匿名化</h4>
    
    <h5>完全匿名化</h5>
    
    <p>这会创建一个数据库的副本并匿名化处理(删除所有笔记内容, 仅保留结构和一些非敏感的元数据信息)用来分享到网上做调试而不用担心泄漏你的个人资料.</p>
    
    <button class="anonymize-full-button btn">保存完全匿名化的数据库</button>

    <h5>轻度匿名化</h5>
    
    <p>此操作将创建数据库的新副本并对其进行轻度匿名化 - 仅删除所有笔记的内容,但标题和属性将保留. 另外, 自定义前端和后端JS代码以及自定义部件会保留. 这会给查找bug提供更完整的环境.</p>
    
    <p>您可以自行决定是否要提供完全或轻度匿名化的数据库. 完全匿名化的数据库会对解决问题有帮助, 在一些情况下, 如果能提供轻度匿名化的数据库能加快定位bug和修复的过程.</p>
    
    <button class="anonymize-light-button btn">保存轻度匿名化的数据库</button>
    
    <h5>已有匿名化数据库</h5>
    
    <ul class="existing-anonymized-databases"></ul>
</div>`;

export default class DatabaseAnonymizationOptions extends OptionsWidget {
    doRender() {
        this.$widget = $(TPL);
        this.$anonymizeFullButton = this.$widget.find(".anonymize-full-button");
        this.$anonymizeLightButton = this.$widget.find(".anonymize-light-button");
        this.$anonymizeFullButton.on('click', async () => {
            toastService.showMessage("创建完全匿名的数据库...");

            const resp = await server.post('database/anonymize/full');

            if (!resp.success) {
                toastService.showError("无法创建匿名化数据库, 详情请检查后台日志.");
            }
            else {
                toastService.showMessage(`成功在 ${resp.anonymizedFilePath} 中创建了完全匿名化的数据库`, 10000);
            }

            this.refresh();
        });

        this.$anonymizeLightButton.on('click', async () => {
            toastService.showMessage("创建轻度匿名数据库...");

            const resp = await server.post('database/anonymize/light');

            if (!resp.success) {
                toastService.showError("无法创建匿名化数据库, 详情请检查后台日志.");
            }
            else {
                toastService.showMessage(`成功在 ${resp.anonymizedFilePath} 中创建了轻度匿名化的数据库`, 10000);
            }

            this.refresh();
        });

        this.$existingAnonymizedDatabases = this.$widget.find(".existing-anonymized-databases");
    }

    optionsLoaded(options) {
        server.get("database/anonymized-databases").then(anonymizedDatabases => {
            this.$existingAnonymizedDatabases.empty();

            if (!anonymizedDatabases.length) {
                anonymizedDatabases = [{filePath: "还没有匿名化的数据库"}];
            }

            for (const {filePath} of anonymizedDatabases) {
                this.$existingAnonymizedDatabases.append($("<li>").text(filePath));
            }
        });
    }
}
