import utils from '../../services/utils.js';
import treeService from "../../services/tree.js";
import importService from "../../services/import.js";
import options from "../../services/options.js";
import BasicWidget from "../basic_widget.js";

const TPL = `
<div class="upload-attachments-dialog modal fade mx-auto" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">上传附件到笔记</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form class="upload-attachment-form">
                <div class="modal-body">
                    <div class="form-group">
                        <label for="upload-attachment-file-upload-input"><strong>选择文件</strong></label>

                        <input type="file" class="upload-attachment-file-upload-input form-control-file" multiple />

                        <p>文件将作为附件上传到<strong class="upload-attachment-note-title"></strong>.
                    </div>

                    <div class="form-group">
                        <strong>选项:</strong>

                        <div class="checkbox">
                            <label data-toggle="tooltip" title="<p>如果您选择此选项, Trilium将尝试通过缩放和优化来缩小上传的图像, 这可能会影响感知的图像质量. 如果未选中, 则图像将以原样上传.</p>">
                                <input class="shrink-images-checkbox" value="1" type="checkbox" checked> <span>压缩图像</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="upload-attachment-button btn btn-primary">上传</button>
                </div>
            </form>
        </div>
    </div>
</div>`;

export default class UploadAttachmentsDialog extends BasicWidget {
    constructor() {
        super();

        this.parentNoteId = null;
    }

    doRender() {
        this.$widget = $(TPL);
        this.$form = this.$widget.find(".upload-attachment-form");
        this.$noteTitle = this.$widget.find(".upload-attachment-note-title");
        this.$fileUploadInput = this.$widget.find(".upload-attachment-file-upload-input");
        this.$uploadButton = this.$widget.find(".upload-attachment-button");
        this.$shrinkImagesCheckbox = this.$widget.find(".shrink-images-checkbox");

        this.$form.on('submit', () => {
            // disabling so that import is not triggered again.
            this.$uploadButton.attr("disabled", "disabled");

            this.uploadAttachments(this.parentNoteId);

            return false;
        });

        this.$fileUploadInput.on('change', () => {
            if (this.$fileUploadInput.val()) {
                this.$uploadButton.removeAttr("disabled");
            }
            else {
                this.$uploadButton.attr("disabled", "disabled");
            }
        });

        this.$widget.find('[data-toggle="tooltip"]').tooltip({
            html: true
        });
    }

    async showUploadAttachmentsDialogEvent({noteId}) {
        this.parentNoteId = noteId;

        this.$fileUploadInput.val('').trigger('change'); // to trigger upload button disabling listener below
        this.$shrinkImagesCheckbox.prop("checked", options.is('compressImages'));

        this.$noteTitle.text(await treeService.getNoteTitle(this.parentNoteId));

        utils.openDialog(this.$widget);
    }

    async uploadAttachments(parentNoteId) {
        const files = Array.from(this.$fileUploadInput[0].files); // shallow copy since we're resetting the upload button below

        const boolToString = $el => $el.is(":checked") ? "true" : "false";

        const options = {
            shrinkImages: boolToString(this.$shrinkImagesCheckbox),
        };

        this.$widget.modal('hide');

        await importService.uploadFiles('attachments', parentNoteId, files, options);
    }
}
