import OptionsWidget from "../options_widget.js";
import toastService from "../../../../services/toast.js";
import server from "../../../../services/server.js";

const TPL = `
<div class="options-section">
    <h4>整理数据库</h4>
    
    <p>这会重建数据库, 通常会减少占用空间, 不会删除数据.</p>
    
    <button class="vacuum-database-button btn">整理数据库</button>
</div>`;

export default class VacuumDatabaseOptions extends OptionsWidget {
    doRender() {
        this.$widget = $(TPL);
        this.$vacuumDatabaseButton = this.$widget.find(".vacuum-database-button");
        this.$vacuumDatabaseButton.on('click', async () => {
            toastService.showMessage("整理数据库...");

            await server.post('database/vacuum-database');

            toastService.showMessage("数据库已整理");
        });
    }
}
