import OptionsWidget from "../options_widget.js";

const TPL = `
<div class="options-section">
    <h4>高亮列表</h4>

    <p>您可以自定义在右侧面板显示的高亮列表:</p>

    </div>
        <label><input type="checkbox" class="highlights-list-check" value="bold"> 粗体字 &nbsp;</label>
        <label><input type="checkbox" class="highlights-list-check" value="italic"> 斜体字 &nbsp;</label>
        <label><input type="checkbox" class="highlights-list-check" value="underline"> 下划线字体 &nbsp;</label>
        <label><input type="checkbox" class="highlights-list-check" value="color"> 彩色字体 &nbsp;</label>
        <label><input type="checkbox" class="highlights-list-check" value="bgColor"> 带有背景色的字体 &nbsp;</label>
    </div>

    <br/><br/>
    <h5>高亮列表显示</h5>

    <p>您可以通过添加 <code>#hideHighlightWidget</code> 标签来在每个笔记中隐藏高亮小部件。</p>
    
    <p>您可以在"选项"->"快捷键"中为快速切换右侧窗格(包括高亮显示)配置键盘快捷键(名称为 "toggleRightPane")</p>
</div>`;

export default class HighlightsListOptions extends OptionsWidget {
    doRender() {
        this.$widget = $(TPL);
        this.$hlt = this.$widget.find("input.highlights-list-check");
        this.$hlt.on('change', () => {
            const hltVals = this.$widget.find('input.highlights-list-check[type="checkbox"]:checked').map(function () {
                return this.value;
            }).get();
            this.updateOption('highlightsList', JSON.stringify(hltVals));
        });
    }

    async optionsLoaded(options) {
        const hltVals = JSON.parse(options.highlightsList);
        this.$widget.find('input.highlights-list-check[type="checkbox"]').each(function () {
            if ($.inArray($(this).val(), hltVals) !== -1) {
                $(this).prop("checked", true);
            } else {
                $(this).prop("checked", false);
            }
        });
    }
}
