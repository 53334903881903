import SpacedUpdate from "../../../services/spaced_update.js";
import AbstractBulkAction from "../abstract_bulk_action.js";

const TPL = `
<tr>
    <td colspan="2">
        <div style="display: flex; align-items: center">
            <div style="margin-right: 10px;" class="text-nowrap">添加标签</div> 
            
            <input type="text" 
                class="form-control label-name" 
                placeholder="标签名称"
                pattern="[\\p{L}\\p{N}_:]+"
                title="允许使用字母数字字符,下划线和冒号."/>
            
            <div style="margin-right: 10px; margin-left: 10px;" class="text-nowrap">为</div>
            
            <input type="text" class="form-control label-value" placeholder="新值"/>
        </div>
    </td>
    <td class="button-column">
        <div class="dropdown help-dropdown">
            <span class="bx bx-help-circle icon-action" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></span>
            <div class="dropdown-menu dropdown-menu-right p-4">
                <p>对于所有匹配的笔记:</p>
                
                <ul>
                    <li>如果笔记还没有, 则创建给定标签</li>
                    <li>或修改现有标签的值</li>
                </ul>
                
                <p>您也可以不带参数地调用这个方法, 这种情况下, 分配给笔记的标签就没有值.</p>
            </div> 
        </div>
    
        <span class="bx bx-x icon-action action-conf-del"></span>
    </td>
</tr>`;

export default class AddLabelBulkAction extends AbstractBulkAction {
    static get actionName() { return "addLabel"; }
    static get actionTitle() { return "添加标签"; }

    doRender() {
        const $action = $(TPL);

        const $labelName = $action.find('.label-name');
        $labelName.val(this.actionDef.labelName || "");

        const $labelValue = $action.find('.label-value');
        $labelValue.val(this.actionDef.labelValue || "");

        const spacedUpdate = new SpacedUpdate(async () => {
            await this.saveAction({
                labelName: $labelName.val(),
                labelValue: $labelValue.val()
            });
        }, 1000);

        $labelName.on('input', () => spacedUpdate.scheduleUpdate());
        $labelValue.on('input', () => spacedUpdate.scheduleUpdate());

        return $action;
    }
}
