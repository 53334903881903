import AbstractSearchOption from "./abstract_search_option.js";
import noteAutocompleteService from "../../services/note_autocomplete.js";

const TPL = `
<tr>
    <td colspan="2">
        <div style="display: flex; align-items: center;">
            <div style="margin-right: 10px">祖先:</div> 
            <div class="input-group" style="flex-shrink: 2">
                <input class="ancestor form-control" placeholder="根据名称搜索笔记">
            </div>
            
            <div style="margin-left: 10px; margin-right: 10px">深度:</div>
            
            <select name="depth" class="form-control d-inline ancestor-depth" style="flex-shrink: 3">
                <option value="">任意</option>
                <option value="eq1">正好是1(直接子代)</option>
                <option value="eq2">正好是2</option>
                <option value="eq3">正好是3</option>
                <option value="eq4">正好是4</option>
                <option value="eq5">正好是5</option>
                <option value="eq6">正好是6</option>
                <option value="eq7">正好是7</option>
                <option value="eq8">正好是8</option>
                <option value="eq9">正好是9</option>
                <option value="gt0">大于0</option>
                <option value="gt1">大于1</option>
                <option value="gt2">大于2</option>
                <option value="gt3">大于3</option>
                <option value="gt4">大于4</option>
                <option value="gt5">大于5</option>
                <option value="gt6">大于6</option>
                <option value="gt7">大于7</option>
                <option value="gt8">大于8</option>
                <option value="gt9">大于9</option>
                <option value="lt2">小于2</option>
                <option value="lt3">小于3</option>
                <option value="lt4">小于4</option>
                <option value="lt5">小于5</option>
                <option value="lt6">小于6</option>
                <option value="lt7">小于7</option>
                <option value="lt8">小于8</option>
                <option value="lt9">小于9</option>
            </select>
        </div>
    </td>
    <td class="button-column">
        <span class="bx bx-x icon-action search-option-del"></span>
    </td>
</tr>`;

export default class Ancestor extends AbstractSearchOption {
    static get optionName() { return "ancestor" };
    static get attributeType() { return "relation" };

    static async create(noteId) {
        await AbstractSearchOption.setAttribute(noteId, 'relation', 'ancestor', 'root');
    }

    doRender() {
        const $option = $(TPL);
        const $ancestor = $option.find('.ancestor');
        const $ancestorDepth = $option.find('.ancestor-depth');
        noteAutocompleteService.initNoteAutocomplete($ancestor);

        $ancestor.on('autocomplete:closed', async () => {
            const ancestorNoteId = $ancestor.getSelectedNoteId();

            if (ancestorNoteId) {
                await this.setAttribute('relation', 'ancestor', ancestorNoteId);
            }
        });

        $ancestorDepth.on('change', async () => {
            const ancestorDepth = $ancestorDepth.val();

            if (ancestorDepth) {
                await this.setAttribute('label', 'ancestorDepth', ancestorDepth);
            }
            else {
                await this.deleteAttribute('label', 'ancestorDepth');
            }
        });

        const ancestorNoteId = this.note.getRelationValue('ancestor');

        if (ancestorNoteId !== 'root') {
            $ancestor.setNote(ancestorNoteId);
        }

        const ancestorDepth = this.note.getLabelValue('ancestorDepth');

        if (ancestorDepth) {
            $ancestorDepth.val(ancestorDepth);
        }

        return $option;
    }

    async deleteOption() {
        await this.deleteAttribute('label', 'ancestorDepth');

        await super.deleteOption();
    }
}
