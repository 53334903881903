import AbstractSearchOption from "./abstract_search_option.js";

const TPL = `
<tr data-search-option-conf="orderBy">
    <td class="title-column">
        <span class="bx bx-arrow-from-top"></span>
    
        排序
    </td>
    <td>
        <select name="orderBy" class="form-control w-auto d-inline">
            <option value="relevancy">相关性 (默认)</option>
            <option value="title">标题</option>
            <option value="dateCreated">创建日期</option>
            <option value="dateModified">最后修改日期</option>
            <option value="contentSize">笔记内容大小</option>
            <option value="contentAndAttachmentsSize">包括附件的笔记内容大小</option>
            <option value="contentAndAttachmentsAndRevisionsSize">包括附件和笔记历史的笔记内容大小</option>
            <option value="revisionCount">历史数量</option>
            <option value="childrenCount">子笔记数</option>
            <option value="parentCount">克隆数</option>
            <option value="ownedLabelCount">标签数量</option>
            <option value="ownedRelationCount">关系数</option>
            <option value="targetRelationCount">指向笔记的关系数</option>
            <option value="random">随机顺序</option>
        </select>
        
        <select name="orderDirection" class="form-control w-auto d-inline">
            <option value="asc">升序(默认)</option>
            <option value="desc">降序</option>
        </select>
    </td>
    <td class="button-column">
        <span class="bx bx-x icon-action search-option-del"></span>
    </td>
</tr>`;

export default class OrderBy extends AbstractSearchOption {
    static get optionName() { return "orderBy" };
    static get attributeType() { return "label" };

    static async create(noteId) {
        await AbstractSearchOption.setAttribute(noteId, 'label', 'orderBy', 'relevancy');
        await AbstractSearchOption.setAttribute(noteId, 'label', 'orderDirection', 'asc');
    }

    doRender() {
        const $option = $(TPL);

        const $orderBy = $option.find('select[name=orderBy]');
        $orderBy.on('change', async () => {
            const orderBy = $orderBy.val();

            await this.setAttribute('label', 'orderBy', orderBy);
        });
        $orderBy.val(this.note.getLabelValue('orderBy'));

        const $orderDirection = $option.find('select[name=orderDirection]');
        $orderDirection.on('change', async () => {
            const orderDirection = $orderDirection.val();

            await this.setAttribute('label', 'orderDirection', orderDirection);
        });
        $orderDirection.val(this.note.getLabelValue('orderDirection') || 'asc');

        return $option;
    }

    async deleteOption() {
        await this.deleteAttribute('label', 'orderDirection');

        await super.deleteOption();
    }
}
