import OptionsWidget from "../options_widget.js";
import toastService from "../../../../services/toast.js";
import server from "../../../../services/server.js";

const TPL = `
<div class="options-section">
    <h4>数据库完整性检查</h4>
    
    <p>检查SQLite数据库是否损坏. 根据数据库大小, 可能会需要一些时间.</p>
    
    <button class="check-integrity-button btn">检查数据库完整性</button>
</div>`;

export default class DatabaseIntegrityCheckOptions extends OptionsWidget {
    doRender() {
        this.$widget = $(TPL);
        this.$checkIntegrityButton = this.$widget.find(".check-integrity-button");
        this.$checkIntegrityButton.on('click', async () => {
            toastService.showMessage("正在检查数据库完整性...");

            const {results} = await server.get('database/check-integrity');

            if (results.length === 1 && results[0].integrity_check === "ok") {
                toastService.showMessage("完整性检查成功 - 未发现问题.");
            }
            else {
                toastService.showMessage(`完整性检查失败: ${JSON.stringify(results, null, 2)}`, 15000);
            }
        });
    }
}
