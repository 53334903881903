import OptionsWidget from "../options_widget.js";

const TPL = `
<div class="options-section">
    <h4>代码笔记自动换行</h4>
    <label>
        <input type="checkbox" class="line-wrap-enabled">
        启用自动换行(需要重新加载前端才会生效)
    </label>
</div>`;

export default class WrapLinesOptions extends OptionsWidget {
    doRender() {
        this.$widget = $(TPL);
        this.$codeLineWrapEnabled = this.$widget.find(".line-wrap-enabled");
        this.$codeLineWrapEnabled.on('change', () =>
            this.updateCheckboxOption('codeLineWrapEnabled', this.$codeLineWrapEnabled));
    }

    async optionsLoaded(options) {
        this.setCheckboxState(this.$codeLineWrapEnabled, options.codeLineWrapEnabled);
    }
}
