import utils from "../../../services/utils.js";
import OptionsWidget from "./options_widget.js";

const TPL = `
<div class="options-section">
    <h4>拼写检查</h4>

    <p>这些选项只在桌面客户端有效, 浏览器会使用浏览器的拼写检查. 修改设置后需要重启应用.</p>

    <label>
        <input type="checkbox" class="spell-check-enabled">
        开启拼写检查
    </label>

    <br/>

    <div class="form-group">
        <label>语言码</label>
        <input type="text" class="spell-check-language-code form-control" placeholder="for example &quot;en-US&quot;, &quot;de-AT&quot;">
    </div>

    <p>多个语言可以用逗号分隔, 例如: <code>en-US, de-DE, cs</code>. 拼写检查选项的修改将在应用程序重新启动后生效.</p>
    
    <p><strong>可用的语言代码</strong> <span class="available-language-codes"></span></p>
</div>`;

export default class SpellcheckOptions extends OptionsWidget {
    doRender() {
        this.$widget = $(TPL);

        this.$spellCheckEnabled = this.$widget.find(".spell-check-enabled");
        this.$spellCheckLanguageCode = this.$widget.find(".spell-check-language-code");

        this.$spellCheckEnabled.on('change', () =>
            this.updateCheckboxOption('spellCheckEnabled', this.$spellCheckEnabled));

        this.$spellCheckLanguageCode.on('change', () =>
            this.updateOption('spellCheckLanguageCode', this.$spellCheckLanguageCode.val()));

        this.$availableLanguageCodes = this.$widget.find(".available-language-codes");

        if (utils.isElectron()) {
            const { webContents } = utils.dynamicRequire('@electron/remote').getCurrentWindow();

            this.$availableLanguageCodes.text(webContents.session.availableSpellCheckerLanguages.join(', '));
        }
    }

    optionsLoaded(options) {
        this.setCheckboxState(this.$spellCheckEnabled, options.spellCheckEnabled);
        this.$spellCheckLanguageCode.val(options.spellCheckLanguageCode);
    }
}
