import server from "../../../services/server.js";
import toastService from "../../../services/toast.js";
import OptionsWidget from "./options_widget.js";

const TPL = `
<div class="options-section">
    <h4 style="margin-top: 0px;">同步设置</h4>
    
    <form class="sync-setup-form">
        <div class="form-group">
            <label>服务器地址</label>
            <input class="sync-server-host form-control" placeholder="https://<host>:<port>">
        </div>
    
        <div class="form-group">
            <label>同步超时(单位:毫秒)</label>
            <input class="sync-server-timeout form-control" min="1" max="10000000" type="number" style="text-align: left;">
        </div>
    
        <div class="form-group">
            <label>同步代理服务器(可选)</label>
            <input class="sync-proxy form-control" placeholder="https://<host>:<port>">
    
            <p><strong>备注:</strong> 代理设置留空则使用系统代理(仅桌面客户端有效).</p>
            <p>另一个特殊值是<code>noproxy</code>，它会强制忽略代理，即使是系统代理也会忽略，并且会遵从<code>NODE_TLS_REJECT_UNAUTHORIZED</code>.</p>
        </div>
    
        <div style="display: flex; justify-content: space-between;">
            <button class="btn btn-primary">保存</button>
    
            <button class="btn" type="button" data-help-page="Synchronization">帮助</button>
        </div>
    </form>
</div>

<div class="options-section">
    <h4>同步测试</h4>
    
    <p>测试和同步服务器之间的连接. 如果同步服务器没有初始化, 会将本地文档同步到同步服务器上.</p>
    
    <button class="test-sync-button btn">测试同步</button>
</div>`;

export default class SyncOptions extends OptionsWidget {
    doRender() {
        this.$widget = $(TPL);

        this.$form = this.$widget.find(".sync-setup-form");
        this.$syncServerHost = this.$widget.find(".sync-server-host");
        this.$syncServerTimeout = this.$widget.find(".sync-server-timeout");
        this.$syncProxy = this.$widget.find(".sync-proxy");
        this.$testSyncButton = this.$widget.find(".test-sync-button");

        this.$form.on('submit', () => this.save());

        this.$testSyncButton.on('click', async () => {
            const result = await server.post('sync/test');

            if (result.success) {
                toastService.showMessage(result.message);
            }
            else {
                toastService.showError(`Sync server handshake failed, error: ${result.message}`);
            }
        });
    }

    optionsLoaded(options) {
        this.$syncServerHost.val(options.syncServerHost);
        this.$syncServerTimeout.val(options.syncServerTimeout);
        this.$syncProxy.val(options.syncProxy);
    }

    save() {
        this.updateMultipleOptions({
            'syncServerHost': this.$syncServerHost.val(),
            'syncServerTimeout': this.$syncServerTimeout.val(),
            'syncProxy': this.$syncProxy.val()
        });

        return false;
    }
}
