import renderService from "../../services/render.js";
import TypeWidget from "./type_widget.js";

const TPL = `
<div class="note-detail-render note-detail-printable">
    <style>
        .note-detail-render {
            position: relative;
        }
    </style>

    <div class="note-detail-render-help alert alert-warning" style="margin: 50px; padding: 20px;">
        <p><strong>之所以显示此帮助说明, 是因为该类型的渲染HTML没有设置好必须的关联关系.</strong></p>

        <p>笔记类型: 渲染HTML 是用来<a class="external" href="https://github.com/zadam/trilium/wiki/Scripts">编写脚本</a>用的. 简单说就是你可以写HTML代码(或者加上一些JavaScript代码), 然后这个笔记会把页面渲染出来. 你得定义一个叫"renderNote"的<a class="external" href="https://github.com/zadam/trilium/wiki/Attributes">关系</a>来指向要渲染的HTML笔记.</p>
    </div>

    <div class="note-detail-render-content"></div>
</div>`;

export default class RenderTypeWidget extends TypeWidget {
    static getType() { return "render"; }

    doRender() {
        this.$widget = $(TPL);
        this.$noteDetailRenderHelp = this.$widget.find('.note-detail-render-help');
        this.$noteDetailRenderContent = this.$widget.find('.note-detail-render-content');

        super.doRender();
    }

    async doRefresh(note) {
        this.$widget.show();
        this.$noteDetailRenderHelp.hide();

        const renderNotesFound = await renderService.render(note, this.$noteDetailRenderContent);

        if (!renderNotesFound) {
            this.$noteDetailRenderHelp.show();
        }
    }

    cleanup() {
        this.$noteDetailRenderContent.empty();
    }

    renderActiveNoteEvent() {
        if (this.noteContext.isActive()) {
            this.refresh();
        }
    }

    async executeWithContentElementEvent({resolve, ntxId}) {
        if (!this.isNoteContext(ntxId)) {
            return;
        }

        await this.initialized;

        resolve(this.$noteDetailRenderContent);
    }
}
