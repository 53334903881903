import OptionsWidget from "../options_widget.js";
import server from "../../../../services/server.js";
import toastService from "../../../../services/toast.js";

const TPL = `
<div class="options-section">
    <h4>同步</h4>
    <button class="force-full-sync-button btn">强制完全同步</button> 
    
    <button class="fill-entity-changes-button btn">填充变化记录</button>
</div>`;

export default class AdvancedSyncOptions extends OptionsWidget {
    doRender() {
        this.$widget = $(TPL);
        this.$forceFullSyncButton = this.$widget.find(".force-full-sync-button");
        this.$fillEntityChangesButton = this.$widget.find(".fill-entity-changes-button");
        this.$forceFullSyncButton.on('click', async () => {
            await server.post('sync/force-full-sync');

            toastService.showMessage("完全同步已触发");
        });

        this.$fillEntityChangesButton.on('click', async () => {
            toastService.showMessage("填充实体修改数据...");

            await server.post('sync/fill-entity-changes');

            toastService.showMessage("同步行已成功填充");
        });
    }

    async optionsLoaded(options) {

    }
}
