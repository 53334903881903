import server from "../../services/server.js";
import froca from "../../services/froca.js";
import linkService from "../../services/link.js";
import attributeAutocompleteService from "../../services/attribute_autocomplete.js";
import noteAutocompleteService from "../../services/note_autocomplete.js";
import promotedAttributeDefinitionParser from '../../services/promoted_attribute_definition_parser.js';
import NoteContextAwareWidget from "../note_context_aware_widget.js";
import SpacedUpdate from "../../services/spaced_update.js";
import utils from "../../services/utils.js";
import shortcutService from "../../services/shortcuts.js";
import appContext from "../../components/app_context.js";

const TPL = `
<div class="attr-detail">
    <style>
        .attr-detail {
            display: block;
            background-color: var(--accented-background-color);
            border: 1px solid var(--main-border-color);
            border-radius: 4px;
            z-index: 1000;
            padding: 15px;
            position: absolute;
            width: 500px;
            max-height: 600px;
            overflow: auto;
            box-shadow: 10px 10px 93px -25px black;
        }
        
        .attr-help td {
            color: var(--muted-text-color);
            padding: 5px;
        }
        
        .related-notes-list {
            padding-left: 20px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        
        .attr-edit-table {
            width: 100%;
        }
        
        .attr-edit-table th {
            text-align: left;
        }
        
        .attr-edit-table td input {
            width: 100%;
        }
        
        .close-attr-detail-button {
            font-size: x-large;
            cursor: pointer;
            position: relative;
            top: -2px;
        }
        
        .attr-save-delete-button-container {
            display: flex; 
            margin-top: 15px;
        }
        
        .attr-detail input[readonly] {
            background-color: var(--accented-background-color) !important;
        }
    </style>

    <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
        <h5 class="attr-detail-title"></h5>
        
        <span class="bx bx-x close-attr-detail-button" title="取消修改并关闭"></span>
    </div>

    <div class="attr-is-owned-by"></div>

    <table class="attr-edit-table">
        <tr title="属性名称只能由字母数字字符, 冒号和下划线组成">
            <th>名称:</th>
            <td><input type="text" class="attr-input-name form-control" /></td>
        </tr>
        <tr class="attr-help"></tr>
        <tr class="attr-row-value">
            <th>值:</th>
            <td><input type="text" class="attr-input-value form-control" /></td>
        </tr>
        <tr class="attr-row-target-note">
            <th title="关系是源笔记和目标笔记之间的命名连接.">目标笔记:</th>
            <td>
                <div class="input-group">
                    <input type="text" class="attr-input-target-note form-control" />
                </div>
            </td>
        </tr>
        <tr class="attr-row-promoted"
            title="升级的属性在笔记上突出显示.">
            <th>升级属性:</th>
            <td><input type="checkbox" class="attr-input-promoted form-control form-control-sm" /></td>
        </tr>
        <tr class="attr-row-promoted-alias">
            <th title="The name to be displayed in the promoted attributes UI.">Alias:</th>
            <td>
                <div class="input-group">
                    <input type="text" class="attr-input-promoted-alias form-control" />
                </div>
            </td>
        </tr>
        <tr class="attr-row-multiplicity">
            <th title="多重性定义可以创建多少个相同名称的属性 - 最大值为1或大于1.">多重性:</th>
            <td>
                <select class="attr-input-multiplicity form-control">
                  <option value="single">单值</option>
                  <option value="multi">多值</option>
                </select>
            </td>
        </tr>
        <tr class="attr-row-label-type">
            <th title="标签的类型将帮助Trilium选择合适的界面来输入标签值.">类型:</th>
            <td>
                <select class="attr-input-label-type form-control">
                  <option value="text">文本</option>
                  <option value="number">数字</option>
                  <option value="boolean">布尔</option>
                  <option value="date">日期</option>
                  <option value="datetime">Date & Time</option>
                  <option value="url">URL</option>
                </select>
            </td>
        </tr>
        <tr class="attr-row-number-precision">
            <th title="值设置界面中可以设置的小数点位数.">精度</th>
            <td>
                <div class="input-group">
                    <input type="number" class="form-control attr-input-number-precision" style="text-align: right">
                    <div class="input-group-append">
                        <span class="input-group-text">位数</span>
                    </div>
                </div>
            </td>
        </tr>
        <tr class="attr-row-inverse-relation">
            <th title="可选设置用于定义与此相对的关系. 比如 父-子 关系是互为父子.">逆关系</th>
            <td>
                <div class="input-group">
                    <input type="text" class="attr-input-inverse-relation form-control" />
                </div>
            </td>
        </tr>
        <tr title="可继承属性将被继承给该树下的所有后代.">
            <th>可继承</th>
            <td><input type="checkbox" class="attr-input-inheritable form-control form-control-sm" /></td>
        </tr>
    </table>

    <div class="attr-save-delete-button-container">
        <button class="btn btn-primary btn-sm attr-save-changes-and-close-button" 
            style="flex-grow: 1; margin-right: 20px">
            保存并关闭 <kbd>Ctrl+回车</kbd></button>
            
        <button class="btn btn-secondary btn-sm attr-delete-button">
            删除</button>
    </div>

    <div class="related-notes-container">
        <br/>

        <h5 class="related-notes-tile">其它含有这个标签的笔记</h5>
        
        <ul class="related-notes-list"></ul>
        
        <div class="related-notes-more-notes"></div>
    </div>
</div>`;

const DISPLAYED_NOTES = 10;

const ATTR_TITLES = {
    "label": "标签详情",
    "label-definition": "标签定义详情",
    "relation": "关系详情",
    "relation-definition": "关系定义详情"
};

const ATTR_HELP = {
    "label": {
        "disableVersioning": "禁用自动版本管理. 对于很大但是不重要的笔记有用, 比如编写脚本用的JS库文件.",
        "calendarRoot": "把笔记标记为日记的根笔记, 这个标签只能有一个笔记有.",
        "archived": "默认情况下, 带有此标签的笔记不会在搜索结果中(在跳转到, 添加链接等对话框中也不会显示).",
        "excludeFromExport": "笔记(及其子树)不会包含在任何笔记导出中",
        "run": `定义哪个事件脚本应该运行. 可取的值有:
                <ul>
                    <li>frontendStartup - 当Trilium前端启动(或刷新)时.</li>
                    <li>backendStartup - 当Trilium后端启动.</li>
                    <li>hourly - 每小时一次. 可以通过 <code>runAtHour</code> 标签来指定.</li>
                    <li>daily - 每天一次</li>
                </ul>`,
        "runOnInstance": "定义应在哪个Trilium实例上运行. 默认是在所有实例上运行.",
        "runAtHour": "定义在什么时候运行. 需要和 <code>#run=hourly</code> 一起使用, 可以同时定义多个来实现一天内多次运行.",
        "disableInclusion": "父级脚本的执行过程中不会包含具有此标签的脚本.",
        "sorted": "使子笔记按标题按字母顺序排序",
        "sortDirection": "ASC升序(默认) 或 DESC降序",
        "sortFoldersFirst": "目录(有子笔记的)在前",
        "top": "将笔记放在其父笔记的顶部(仅适用于排序的父级)",
        "hidePromotedAttributes": "隐藏此笔记中的升级属性",
        "readOnly": "编辑器处于只读模式. 只对文本和代码笔记有效.",
        "autoReadOnlyDisabled": "文本/代码笔记过大时, 可以自动设置为阅读模式. 你可以通过给需要的每个笔记添加这个标签来单独禁用这个功能.",
        "appCss": "标记可以被Trilium加载用来修改Trilium的外观的CSS笔记.",
        "appTheme": "标记可以被作为Trilium主题使用的CSS笔记, 这些笔记可以在Trilium的设置里看到.",
        "cssClass": "这个标签的值会被当作CSS类加到对应的笔记中, 可以被用来单独设置笔记的主题. 这个标签也可以在模板笔记里使用.",
        "iconClass": "这个标签的值作为CSS类添加到笔记上, 用来修改对应笔记的图标. 比如 bx bx-home 会使用 boxicons 相应的图标. 这个标签也可以在模板笔记里使用. ",
        "pageSize": "笔记清单中每页的个数",
        "customRequestHandler": '见 <a href="javascript:" data-help-page="Custom request handler">自定义请求处理器</a>',
        "customResourceProvider": '见 <a href="javascript:" data-help-page="Custom request handler">自定义请求处理器</a>',
        "widget": "将此笔记标记为自定义窗口小部件, 该窗口小部件会被添加到Trilium部件树中",
        "workspace": "将此笔记标记为可简单提升的工作区",
        "workspaceIconClass": "提升此笔记时, 笔记标签页中使用的CSS图标",
        "workspaceTabBackgroundColor": "提升此笔记时, 笔记标签页中使用的CSS颜色",
        "workspaceCalendarRoot": "定义每个工作空间的日历根笔记",
        "workspaceTemplate": "这个笔记会出现在新建笔记时的可用模板选择中, 但是只有在提升笔记中包含这个笔记才有效",
        "searchHome": "新的搜索记录将被创建为该笔记的子笔记",
        "workspaceSearchHome": "当笔记的某些父级笔记被提升时, 新的搜索记录将被创建为该笔记的子笔记",
        "inbox": "新笔记的默认收件箱位置 - 当你使用侧边栏的\"新建笔记\"按钮, 笔记会被创建为标记有<code>#inbox</code>标签的笔记的子笔记",
        "workspaceInbox": "当笔记的某些父级笔记被提升时,  新笔记的默认收件箱位置",
        "sqlConsoleHome": "SQL控制台笔记的默认位置",
        "bookmarkFolder": "带有此标签的笔记将作为文件夹出现在书签中(允许访问其子笔记)",
        "shareHiddenFromTree": "此笔记在左侧笔记树中隐藏, 但仍可通过其 URL 访问",
        "shareExternalLink": "note will act as a link to an external website in the share tree",
        "shareAlias": "定义一个别名,使用该别名可以在 https://服务器地址/share/[你定义的别名] 下使用该笔记",
        "shareOmitDefaultCss": "默认共享页面 CSS 将被忽略. 当你想自定义样式时请使用这个标签.",
        "shareRoot": "将笔记标记为 /share 访问的首页",
        "shareDescription": "定义需要加入到HTML的meta标签的文字",
        "shareRaw": "笔记会通过原始格式直接展示, 不会通过HTML页面封装",
        "shareDisallowRobotIndexing": `通过 <code>X-Robots-Tag: noindex</code> 请求头来阻止爬虫机器人抓取该笔记`,
        "shareCredentials": "需要身份验证才能访问此共享笔记. 值的格式'用户名:密码'. 别忘了设置为可继承来对所有子笔记和图片生效.",
        "shareIndex": "有这个标签的笔记会列出所有分享笔记的根笔记",
        "displayRelations": "显示通过逗号分隔的关联关系. 其它的会被隐藏.",
        "hideRelations": "隐藏通过逗号分隔的关联关系, 而其它的会显示出来.",
        "titleTemplate": `当前笔记所属子笔记的默认标题. 这里的字符会被作为Javascript解析, 因此可以使用 <code>now</code> 和 <code>parentNote</code> 来生成动态标题. 例如:
                        
                        <ul>
                            <li><code>\${parentNote.getLabelValue('authorName')}的文学作品</code></li>
                            <li><code>\${now.format('YYYY-MM-DD HH:mm:ss')} 的日志</code></li>
                        </ul>
                        
                        详见 <a href="https://github.com/zadam/trilium/wiki/Default-note-title">wiki</a>. <a href="https://zadam.github.io/trilium/backend_api/Note.html">parentNote</a> 和 <a href="https://day.js.org/docs/en/display/format">now</a> 的API文档.`,
        "template": "这个笔记会出现在新建笔记时的可用模板选择中",
        "toc": "<code>#toc</code> or <code>#toc=show</code>将会强制显示目录,<code>#toc=hide</code> 会隐藏它 如果标签不存在, 会遵循全局设置的配置",
        "color": "定义笔记树、链接等中笔记的颜色. 任何有效的CSS颜色值都可以使用, 例如 'red' 或 #a13d5f",
        "keyboardShortcut": "定义立即跳转到此笔记的键盘快捷键.例如: 'ctrl+alt+e'. 更改需要重新加载前端才能生效.",
        "keepCurrentHoisting": "即使该笔记在当前提升的子树中无法显示, 打开此链接也不会更改提升.",
        "executeButton": "执行当前代码笔记的按钮的标题",
        "executeDescription": "与执行按钮一起显示的当前代码笔记的较长描述",
        "excludeFromNoteMap": "具有此标签的笔记将从笔记地图中隐藏",
        "newNotesOnTop": "新笔记将创建在父笔记的顶部, 而不是底部.",
        "hideHighlightWidget": "Hide Hightlight List widget"
    },
    "relation": {
        "runOnNoteCreation": "在后端创建笔记时执行. 如果要针对特定子树下创建的所有笔记运行脚本，请使用此关系。在这种情况下，在子树根笔记上创建它并使其可继承。在子树中（任何深度）创建新笔记将触发脚本。",
        "runOnChildNoteCreation": "在定义此关系的笔记下创建新笔记时执行",
        "runOnNoteTitleChange": "笔记标题修改时执行(还包括笔记创建)",
        "runOnNoteContentChange": "当笔记内容更改（包括笔记创建）时执行",
        "runOnNoteChange": "修改笔记时执行(还包括笔记创建). 不包括内容更改",
        "runOnNoteDeletion": "在笔记删除时执行",
        "runOnBranchCreation": "在分支创建时执行. 分支是父笔记和子笔记之间的连接, 会在比如克隆笔记或移动笔记时创建.",
        "runOnBranchChange": "executes when a branch is updated.",
        "runOnBranchDeletion": "在分支删除时执行. 分支是父笔记和子笔记之间的连接, 会在比如移动笔记(旧分支/连接删除)时删除.",
        "runOnAttributeCreation": "在定义此关系的笔记上创建新属性时执行",
        "runOnAttributeChange": " 当定义此关系的笔记的属性更改时执行。当删除属性时，也会触发此操作",
        "template": "即使没有父子关系, 笔记的属性也会被继承, 如果为空, 则笔记的内容和子树将添加到实例笔记中. 有关详细信息请参阅文档.",
        "inherit": "即使没有父子关系, 笔记的属性也将被继承. 有关类似概念的模板关系, 请参阅文档中的属性继承.",
        "renderNote": '类型为"渲染笔记"的笔记会被作为代码笔记(HTML或脚本)显示, 并且必须指定需要渲染的笔记关系.',
        "widget": "此关系的目标将被执行, 并在侧边栏中显示为小部件",
        "shareCss": "会被注入到分享页面的CSS笔记. CSS笔记必须也在共享笔记的子树范围内. 请考同时使用 'shareHiddenFromTree' 和 'shareOmitDefaultCss' 标签.",
        "shareJs": "会被注入到分享页面的JS笔记. JS笔记必须也在共享笔记的子树范围内. 请考同时使用 'shareHiddenFromTree' 标签.",
        "shareTemplate": "Embedded JavaScript note that will be used as the template for displaying the shared note. Falls back to the default template. Consider using 'shareHiddenFromTree'.",
        "shareFavicon": "设置为共享页面 Favicon 的笔记. 一般把它放到共享笔记的根目录, 同时让它设置为可继承的. Favicon 笔记必须也在共享笔记的子树范围内. 请考虑同时使用 'shareHiddenFromTree' 标签",
    }
};

export default class AttributeDetailWidget extends NoteContextAwareWidget {
    async refresh() {
        // switching note/tab should close the widget

        this.hide();
    }

    doRender() {
        this.relatedNotesSpacedUpdate = new SpacedUpdate(async () => this.updateRelatedNotes(), 1000);

        this.$widget = $(TPL);

        shortcutService.bindElShortcut(this.$widget, 'ctrl+return', () => this.saveAndClose());
        shortcutService.bindElShortcut(this.$widget, 'esc', () => this.cancelAndClose());


        this.$title = this.$widget.find('.attr-detail-title');

        this.$inputName = this.$widget.find('.attr-input-name');
        this.$inputName.on('input', ev => {
            if (!ev.originalEvent?.isComposing) { // https://github.com/zadam/trilium/pull/3812
                this.userEditedAttribute();
            }
        });
        this.$inputName.on('change', () => this.userEditedAttribute());
        this.$inputName.on('autocomplete:closed', () => this.userEditedAttribute());

        this.$inputName.on('focus', () => {
            attributeAutocompleteService.initAttributeNameAutocomplete({
                $el: this.$inputName,
                attributeType: () => ['relation', 'relation-definition'].includes(this.attrType) ? 'relation' : 'label',
                open: true
            });
        });

        this.$rowValue = this.$widget.find('.attr-row-value');
        this.$inputValue = this.$widget.find('.attr-input-value');
        this.$inputValue.on('input', ev => {
            if (!ev.originalEvent?.isComposing) { // https://github.com/zadam/trilium/pull/3812
                this.userEditedAttribute();
            }
        });
        this.$inputValue.on('change', () => this.userEditedAttribute());
        this.$inputValue.on('autocomplete:closed', () => this.userEditedAttribute());
        this.$inputValue.on('focus', () => {
            attributeAutocompleteService.initLabelValueAutocomplete({
                $el: this.$inputValue,
                open: true,
                nameCallback: () => this.$inputName.val()
            });
        });

        this.$rowPromoted = this.$widget.find('.attr-row-promoted');
        this.$inputPromoted = this.$widget.find('.attr-input-promoted');
        this.$inputPromoted.on('change', () => this.userEditedAttribute());

        this.$rowPromotedAlias = this.$widget.find('.attr-row-promoted-alias');
        this.$inputPromotedAlias = this.$widget.find('.attr-input-promoted-alias');
        this.$inputPromotedAlias.on('change', () => this.userEditedAttribute());

        this.$rowMultiplicity = this.$widget.find('.attr-row-multiplicity');
        this.$inputMultiplicity = this.$widget.find('.attr-input-multiplicity');
        this.$inputMultiplicity.on('change', () => this.userEditedAttribute());

        this.$rowLabelType = this.$widget.find('.attr-row-label-type');
        this.$inputLabelType = this.$widget.find('.attr-input-label-type');
        this.$inputLabelType.on('change', () => this.userEditedAttribute());

        this.$rowNumberPrecision = this.$widget.find('.attr-row-number-precision');
        this.$inputNumberPrecision = this.$widget.find('.attr-input-number-precision');
        this.$inputNumberPrecision.on('change', () => this.userEditedAttribute());

        this.$rowInverseRelation = this.$widget.find('.attr-row-inverse-relation');
        this.$inputInverseRelation = this.$widget.find('.attr-input-inverse-relation');
        this.$inputInverseRelation.on('input', ev => {
            if (!ev.originalEvent?.isComposing) { // https://github.com/zadam/trilium/pull/3812
                this.userEditedAttribute();
            }
        });

        this.$rowTargetNote = this.$widget.find('.attr-row-target-note');
        this.$inputTargetNote = this.$widget.find('.attr-input-target-note');

        noteAutocompleteService.initNoteAutocomplete(this.$inputTargetNote, {allowCreatingNotes: true})
            .on('autocomplete:noteselected', (event, suggestion, dataset) => {
                if (!suggestion.notePath) {
                    return false;
                }

                const pathChunks = suggestion.notePath.split('/');

                this.attribute.value = pathChunks[pathChunks.length - 1]; // noteId

                this.triggerCommand('updateAttributeList', { attributes: this.allAttributes });
                this.updateRelatedNotes();
            });

        this.$inputInheritable = this.$widget.find('.attr-input-inheritable');
        this.$inputInheritable.on('change', () => this.userEditedAttribute());

        this.$closeAttrDetailButton = this.$widget.find('.close-attr-detail-button');
        this.$closeAttrDetailButton.on('click', () => this.cancelAndClose());

        this.$attrIsOwnedBy = this.$widget.find('.attr-is-owned-by');

        this.$attrSaveDeleteButtonContainer = this.$widget.find('.attr-save-delete-button-container');

        this.$saveAndCloseButton = this.$widget.find('.attr-save-changes-and-close-button');
        this.$saveAndCloseButton.on('click', () => this.saveAndClose());

        this.$deleteButton = this.$widget.find('.attr-delete-button');
        this.$deleteButton.on('click', async () => {
            await this.triggerCommand('updateAttributeList', {
                attributes: this.allAttributes.filter(attr => attr !== this.attribute)
            });

            await this.triggerCommand('saveAttributes');

            this.hide();
        });

        this.$attrHelp = this.$widget.find('.attr-help');

        this.$relatedNotesContainer = this.$widget.find('.related-notes-container');
        this.$relatedNotesTitle = this.$relatedNotesContainer.find('.related-notes-tile');
        this.$relatedNotesList = this.$relatedNotesContainer.find('.related-notes-list');
        this.$relatedNotesMoreNotes = this.$relatedNotesContainer.find('.related-notes-more-notes');

        $(window).on('mousedown', e => {
            if (!$(e.target).closest(this.$widget[0]).length
                && !$(e.target).closest(".algolia-autocomplete").length
                && !$(e.target).closest("#context-menu-container").length) {
                this.hide();
            }
        });
    }

    async showAttributeDetail({allAttributes, attribute, isOwned, x, y, focus}) {
        if (!attribute) {
            this.hide();

            return;
        }

        utils.saveFocusedElement();

        this.attrType = this.getAttrType(attribute);

        const attrName =
            this.attrType === 'label-definition' ? attribute.name.substr(6)
                : (this.attrType === 'relation-definition' ? attribute.name.substr(9) : attribute.name);

        const definition = this.attrType.endsWith('-definition')
            ? promotedAttributeDefinitionParser.parse(attribute.value)
            : {};

        this.$title.text(ATTR_TITLES[this.attrType]);

        this.allAttributes = allAttributes;
        this.attribute = attribute;

        // can be slightly slower so just make it async
        this.updateRelatedNotes();

        this.$attrSaveDeleteButtonContainer.toggle(!!isOwned);

        if (isOwned) {
            this.$attrIsOwnedBy.hide();
        }
        else {
            this.$attrIsOwnedBy
                .show()
                .empty()
                .append(attribute.type === 'label' ? 'Label' : 'Relation')
                .append(' is owned by note ')
                .append(await linkService.createLink(attribute.noteId))
        }

        this.$inputName
            .val(attrName)
            .attr('readonly', () => !isOwned);

        this.$rowValue.toggle(this.attrType === 'label');
        this.$rowTargetNote.toggle(this.attrType === 'relation');

        this.$rowPromoted.toggle(['label-definition', 'relation-definition'].includes(this.attrType));
        this.$inputPromoted
            .prop("checked", !!definition.isPromoted)
            .attr('disabled', () => !isOwned);

        this.$rowPromotedAlias.toggle(!!definition.isPromoted);
        this.$inputPromotedAlias
            .val(definition.promotedAlias)
            .attr('disabled', () => !isOwned);

        this.$rowMultiplicity.toggle(['label-definition', 'relation-definition'].includes(this.attrType));
        this.$inputMultiplicity
            .val(definition.multiplicity)
            .attr('disabled', () => !isOwned);

        this.$rowLabelType.toggle(this.attrType === 'label-definition');
        this.$inputLabelType
            .val(definition.labelType)
            .attr('disabled', () => !isOwned);

        this.$rowNumberPrecision.toggle(this.attrType === 'label-definition' && definition.labelType === 'number');
        this.$inputNumberPrecision
            .val(definition.numberPrecision)
            .attr('disabled', () => !isOwned);

        this.$rowInverseRelation.toggle(this.attrType === 'relation-definition');
        this.$inputInverseRelation
            .val(definition.inverseRelation)
            .attr('disabled', () => !isOwned);

        if (attribute.type === 'label') {
            this.$inputValue
                .val(attribute.value)
                .attr('readonly', () => !isOwned);
        }
        else if (attribute.type === 'relation') {
            this.$inputTargetNote
                .attr('readonly', () => !isOwned)
                .val("")
                .setSelectedNotePath("");

            if (attribute.value) {
                const targetNote = await froca.getNote(attribute.value);

                if (targetNote) {
                    this.$inputTargetNote
                        .val(targetNote ? targetNote.title : "")
                        .setSelectedNotePath(attribute.value);
                }
            }
        }

        this.$inputInheritable
            .prop("checked", !!attribute.isInheritable)
            .attr('disabled', () => !isOwned);

        this.updateHelp();

        this.toggleInt(true);

        const offset = this.parent.$widget.offset();
        const detPosition = this.getDetailPosition(x, offset);

        this.$widget
            .css("left", detPosition.left)
            .css("right", detPosition.right)
            .css("top", y - offset.top + 70)
            .css("max-height",
                this.$widget.outerHeight() + y > $(window).height() - 50
                    ? $(window).height() - y - 50
                    : 10000);

        if (focus === 'name') {
            this.$inputName
                .trigger('focus')
                .trigger('select');
        }
    }

    getDetailPosition(x, offset) {
        let left = x - offset.left - this.$widget.outerWidth() / 2;
        let right = "";

        if (left < 0) {
            left = 10;
        } else {
            const rightEdge = left + this.$widget.outerWidth();

            if (rightEdge > this.parent.$widget.outerWidth() - 10) {
                left = "";
                right = 10;
            }
        }

        return {left, right};
    }

    async saveAndClose() {
        await this.triggerCommand('saveAttributes');

        this.hide();

        utils.focusSavedElement();
    }

    async cancelAndClose() {
        await this.triggerCommand('reloadAttributes');

        this.hide();

        utils.focusSavedElement();
    }

    userEditedAttribute() {
        this.updateAttributeInEditor();
        this.updateHelp();
        this.relatedNotesSpacedUpdate.scheduleUpdate();
    }

    updateHelp() {
        const attrName = this.$inputName.val();

        if (this.attrType in ATTR_HELP && attrName in ATTR_HELP[this.attrType]) {
            this.$attrHelp
                .empty()
                .append($("<td colspan=2>")
                    .append($("<strong>").text(attrName))
                    .append(" - ")
                    .append(ATTR_HELP[this.attrType][attrName])
                )
                .show();
        }
        else {
            this.$attrHelp.empty().hide();
        }
    }

    async updateRelatedNotes() {
        let {results, count} = await server.post('search-related', this.attribute);

        for (const res of results) {
            res.noteId = res.notePathArray[res.notePathArray.length - 1];
        }

        results = results.filter(({noteId}) => noteId !== this.noteId);

        if (results.length === 0) {
            this.$relatedNotesContainer.hide();
        } else {
            this.$relatedNotesContainer.show();
            this.$relatedNotesTitle.text(`其它含有${this.attribute.type}名为"${this.attribute.name}"的笔记`);

            this.$relatedNotesList.empty();

            const displayedResults = results.length <= DISPLAYED_NOTES ? results : results.slice(0, DISPLAYED_NOTES);
            const displayedNotes = await froca.getNotes(displayedResults.map(res => res.noteId));
            const hoistedNoteId = appContext.tabManager.getActiveContext()?.hoistedNoteId;

            for (const note of displayedNotes) {
                const notePath = note.getBestNotePathString(hoistedNoteId);
                const $noteLink = await linkService.createLink(notePath, {showNotePath: true});

                this.$relatedNotesList.append(
                    $("<li>").append($noteLink)
                );
            }

            if (results.length > DISPLAYED_NOTES) {
                this.$relatedNotesMoreNotes.show().text(`... and ${count - DISPLAYED_NOTES} more.`);
            } else {
                this.$relatedNotesMoreNotes.hide();
            }
        }
    }

    getAttrType(attribute) {
        if (attribute.type === 'label') {
            if (attribute.name.startsWith('label:')) {
                return "label-definition";
            } else if (attribute.name.startsWith('relation:')) {
                return "relation-definition";
            } else {
                return "label";
            }
        }
        else if (attribute.type === 'relation') {
            return "relation";
        }
        else {
            this.$title.text('');
        }
    }

    updateAttributeInEditor() {
        let attrName = this.$inputName.val();

        if (!utils.isValidAttributeName(attrName)) {
            // invalid characters are simply ignored (from user perspective they are not even entered)
            attrName = utils.filterAttributeName(attrName);

            this.$inputName.val(attrName);
        }

        if (this.attrType === 'label-definition') {
            attrName = `label:${attrName}`;
        } else if (this.attrType === 'relation-definition') {
            attrName = `relation:${attrName}`;
        }

        this.attribute.name = attrName;
        this.attribute.isInheritable = this.$inputInheritable.is(":checked");

        if (this.attrType.endsWith('-definition')) {
            this.attribute.value = this.buildDefinitionValue();
        }
        else if (this.attrType === 'relation') {
            this.attribute.value = this.$inputTargetNote.getSelectedNoteId();
        }
        else {
            this.attribute.value = this.$inputValue.val();
        }

        this.triggerCommand('updateAttributeList', { attributes: this.allAttributes });
    }

    buildDefinitionValue() {
        const props = [];

        if (this.$inputPromoted.is(":checked")) {
            props.push("promoted");

            if (this.$inputPromotedAlias.val() !== '') {
                props.push(`alias=${this.$inputPromotedAlias.val()}`);
            }
        }

        props.push(this.$inputMultiplicity.val());

        if (this.attrType === 'label-definition') {
            props.push(this.$inputLabelType.val());

            if (this.$inputLabelType.val() === 'number' && this.$inputNumberPrecision.val() !== '') {
                props.push(`precision=${this.$inputNumberPrecision.val()}`);
            }
        } else if (this.attrType === 'relation-definition' && this.$inputInverseRelation.val().trim().length > 0) {
            const inverseRelationName = this.$inputInverseRelation.val();

            props.push(`inverse=${utils.filterAttributeName(inverseRelationName)}`);
        }

        this.$rowNumberPrecision.toggle(
            this.attrType === 'label-definition'
            && this.$inputLabelType.val() === 'number');

        this.$rowPromotedAlias.toggle(this.$inputPromoted.is(":checked"));

        return props.join(",");
    }

    hide() {
        this.toggleInt(false);
    }

    createLink(noteId) {
        return $("<a>", {
            href: `#root/${noteId}`,
            class: 'reference-link'
        });
    }

    async noteSwitched() {
        this.hide();
    }
}
