import OptionsWidget from "../options_widget.js";

const TPL = `
<div class="options-section">
    <h4>目录</h4>
    
    当笔记具有超过定义数量的标题时, 目录将出现在文本笔记中. 你可以自定义这个数值:
    
    <div class="form-group">
        <input type="number" class="min-toc-headings form-control options-number-input options-number-input" min="0" max="9999999999999999" step="1" />
    </div>
    
    <p>您可以设置一个非常大的数来禁用目录.</p>
    
    <p>您可以在"选项"->"快捷键"中为快速切换右侧窗格(包括目录)配置键盘快捷键(名称为 "toggleRightPane")</p>
</div>`;

export default class TableOfContentsOptions extends OptionsWidget {
    doRender() {
        this.$widget = $(TPL);
        this.$minTocHeadings = this.$widget.find(".min-toc-headings");
        this.$minTocHeadings.on('change', () =>
            this.updateOption('minTocHeadings', this.$minTocHeadings.val()));
    }

    async optionsLoaded(options) {
        this.$minTocHeadings.val(options.minTocHeadings);
    }
}
