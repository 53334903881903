import BasicWidget from "../basic_widget.js";
import server from "../../services/server.js";
import dialogService from "../../services/dialog.js";
import toastService from "../../services/toast.js";
import ws from "../../services/ws.js";
import appContext from "../../components/app_context.js";
import openService from "../../services/open.js";
import utils from "../../services/utils.js";

const TPL = `
<div class="dropdown attachment-actions">
    <style>
    .attachment-actions {
        width: 35px;
        height: 35px;
    }
    
    .attachment-actions .dropdown-menu {
        width: 20em;
    }
    
    .attachment-actions .dropdown-item[disabled], .attachment-actions .dropdown-item[disabled]:hover {
        color: var(--muted-text-color) !important;
        background-color: transparent !important;
        pointer-events: none; /* makes it unclickable */
    }
    </style>

    <button type="button" data-toggle="dropdown" aria-haspopup="true" 
        aria-expanded="false" class="icon-action icon-action-always-border bx bx-dots-vertical-rounded"
        style="position: relative; top: 3px;"></button>

    <div class="dropdown-menu dropdown-menu-right">
        <a data-trigger-command="openAttachment" class="dropdown-item"
            title="文件将在外部应用程序中打开并监视更改, 然后您可以将修改后的版本上传回Trilium">用外部程序打开</a>
        <a data-trigger-command="openAttachmentCustom" class="dropdown-item"
            title="文件将在外部应用程序中打开并监视更改, 然后您可以将修改后的版本上传回Trilium">使用自定义程序打开</a>
        <a data-trigger-command="downloadAttachment" class="dropdown-item">下载</a>
        <a data-trigger-command="renameAttachment" class="dropdown-item">重命名附件</a>
        <a data-trigger-command="uploadNewAttachmentRevision" class="dropdown-item">上传新的修改</a>
        <a data-trigger-command="copyAttachmentLinkToClipboard" class="dropdown-item">复制链接到剪贴板</a>
        <a data-trigger-command="convertAttachmentIntoNote" class="dropdown-item">将附件转换为笔记</a>
        <a data-trigger-command="deleteAttachment" class="dropdown-item">删除附件</a>
    </div>
    
    <input type="file" class="attachment-upload-new-revision-input" style="display: none">
</div>`;

export default class AttachmentActionsWidget extends BasicWidget {
    constructor(attachment, isFullDetail) {
        super();

        this.attachment = attachment;
        this.isFullDetail = isFullDetail;
    }

    get attachmentId() {
        return this.attachment.attachmentId;
    }

    doRender() {
        this.$widget = $(TPL);
        this.$widget.on('click', '.dropdown-item', () => this.$widget.find("[data-toggle='dropdown']").dropdown('toggle'));

        this.$uploadNewRevisionInput = this.$widget.find(".attachment-upload-new-revision-input");
        this.$uploadNewRevisionInput.on('change', async () => {
            const fileToUpload = this.$uploadNewRevisionInput[0].files[0]; // copy to allow reset below
            this.$uploadNewRevisionInput.val('');

            const result = await server.upload(`attachments/${this.attachmentId}/file`, fileToUpload);

            if (result.uploaded) {
                toastService.showMessage("已上传新附件版本。");
            } else {
                toastService.showError("上传新附件版本失败。");
            }
        });

        if (!this.isFullDetail) {
            // we deactivate this button because the WatchedFileUpdateStatusWidget assumes only one visible attachment
            // in a note context, so it doesn't work in a list
            const $openAttachmentButton = this.$widget.find("[data-trigger-command='openAttachment']");
            $openAttachmentButton
                .addClass("disabled")
                .append($('<span class="disabled-tooltip"> (?)</span>')
                    .attr("title", "用外部程序打开附件仅在详细页面使用, 请先单击附件详情并重复操作")
                );
            const $openAttachmentCustomButton = this.$widget.find("[data-trigger-command='openAttachmentCustom']");
            $openAttachmentCustomButton
                .addClass("disabled")
                .append($('<span class="disabled-tooltip"> (?)</span>')
                    .attr("title", "用外部程序打开附件仅在详细页面使用, 请先单击附件详情并重复操作")
                );
        }
        if (!utils.isElectron()){
            const $openAttachmentCustomButton = this.$widget.find("[data-trigger-command='openAttachmentCustom']");
            $openAttachmentCustomButton
                .addClass("disabled")
                .append($('<span class="disabled-tooltip"> (?)</span>')
                    .attr("title", "Custom opening of attachments can only be done from the client.")
                );
        }
    }

    async openAttachmentCommand() {
        await openService.openAttachmentExternally(this.attachmentId, this.attachment.mime);
    }

    async openAttachmentCustomCommand() {
        await openService.openAttachmentCustom(this.attachmentId, this.attachment.mime);
    }

    async downloadAttachmentCommand() {
        await openService.downloadAttachment(this.attachmentId);
    }

    async uploadNewAttachmentRevisionCommand() {
        this.$uploadNewRevisionInput.trigger('click');
    }

    async copyAttachmentLinkToClipboardCommand() {
        this.parent.copyAttachmentLinkToClipboard();
    }

    async deleteAttachmentCommand() {
        if (!await dialogService.confirm(`您确定要删除附件吗 '${this.attachment.title}'?`)) {
            return;
        }

        await server.remove(`attachments/${this.attachmentId}`);
        toastService.showMessage(`已删除附件 '${this.attachment.title}'`);
    }

    async convertAttachmentIntoNoteCommand() {
        if (!await dialogService.confirm(`您确定要将附件 '${this.attachment.title}' 转换为独立的笔记吗?`)) {
            return;
        }

        const {note: newNote} = await server.post(`attachments/${this.attachmentId}/convert-to-note`)
        toastService.showMessage(`附件 '${this.attachment.title}' 已转换为笔记`);
        await ws.waitForMaxKnownEntityChangeId();
        await appContext.tabManager.getActiveContext().setNote(newNote.noteId);
    }

    async renameAttachmentCommand() {
        const attachmentTitle = await dialogService.prompt({
            title: "重命名附件",
            message: "请输入新附件的名称",
            defaultValue: this.attachment.title
        });

        if (!attachmentTitle?.trim()) {
            return;
        }

        await server.put(`attachments/${this.attachmentId}/rename`, {title: attachmentTitle});
    }
}
