import OptionsWidget from "../options_widget.js";

const TPL = `
<div class="options-section">
    <h4>笔记修改快照间隔</h4>

    <p>这个是笔记修改后创建快照的时间. 详见:<a href="https://github.com/zadam/trilium/wiki/Note-revisions" class="external">wiki</a> 以获取更多信息</p>

    <div class="form-group">
        <label>笔记修改快照间隔(单位:秒)</label>
        <input class="revision-snapshot-time-interval-in-seconds form-control options-number-input" type="number" min="10">
    </div>
</div>`;

export default class RevisionsSnapshotIntervalOptions extends OptionsWidget {
    doRender() {
        this.$widget = $(TPL);
        this.$revisionsTimeInterval = this.$widget.find(".revision-snapshot-time-interval-in-seconds");
        this.$revisionsTimeInterval.on('change', () =>
            this.updateOption('revisionSnapshotTimeInterval', this.$revisionsTimeInterval.val()));
    }

    async optionsLoaded(options) {
        this.$revisionsTimeInterval.val(options.revisionSnapshotTimeInterval);
    }
}
