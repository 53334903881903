import utils from "../../services/utils.js";
import BasicWidget from "../basic_widget.js";

const TPL = `
<div class="help-dialog modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document" style="min-width: 100%; height: 100%; margin: 0;">
        <div class="modal-content" style="height: auto;">
            <div class="modal-header">
                <h5 class="modal-title mr-auto">帮助 (完整<a class="external" href="https://github.com/zadam/trilium/wiki">在线文档</a>)</h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="overflow: auto; height: calc(100vh - 70px);">
                <div class="card-columns help-cards">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">笔记导航</h5>

                            <p class="card-text">
                                <ul>
                                    <li><kbd>上</kbd>, <kbd>下</kbd>-在笔记列表中向上/向下</li>
                                    <li><kbd>左</kbd>, <kbd>右</kbd> - 折叠/展开节点</li>
                                    <li><kbd data-command="backInNoteHistory">not set</kbd>, <kbd data-command="forwardInNoteHistory">not set</kbd> - 历史浏览记录后退/前进</li>
                                    <li><kbd data-command="jumpToNote">not set</kbd>- 显示<a class="external" href="https://github.com/zadam/trilium/wiki/Note-navigation#jump-to-note">"跳转到"对话框</a></li>
                                    <li><kbd data-command="scrollToActiveNote">not set</kbd> - 滚动到当前笔记</li>
                                    <li><kbd>退格键</kbd> - 跳转到父级笔记</li>
                                    <li><kbd data-command="collapseTree">not set</kbd> - 折叠整个笔记树</li>
                                    <li><kbd data-command="collapseSubtree">not set</kbd> - 折叠子树</li>
                                </ul>
                            </p>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">标签快捷方式</h5>

                            <p class="card-text">
                            <ul>
                                <li><kbd>CTRL+鼠标左键</kbd>(或单击鼠标中键)在新标签页中打开笔记链接</li>
                            </ul>

                            仅桌面客户端有效 (electron 编译版):
                            <ul>
                                <li><kbd data-command="openNewTab">not set</kbd>打开空白标签</li>
                                <li><kbd data-command="closeActiveTab">not set</kbd>关闭活动标签</li>
                                <li><kbd data-command="activateNextTab">not set</kbd>激活下一个标签</li>
                                <li><kbd data-command="activatePreviousTab">not set</kbd>激活上一个标签</li>
                            </ul>
                            </p>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">创建笔记</h5>

                            <p class="card-text">
                                <ul>
                                    <li><kbd data-command="createNoteAfter">not set</kbd>- 在当前笔记后创建新笔记</li>
                                    <li><kbd data-command="createNoteInto">not set</kbd>-在当前笔记中创建新的子笔记</li>
                                    <li><kbd data-command="editBranchPrefix">not set</kbd> - 编辑活跃笔记的 <a class="external" href="https://github.com/zadam/trilium/wiki/Tree concepts#prefix">前缀</a></li>
                                </ul>
                            </p>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">移动/克隆笔记</h5>

                            <p class="card-text">
                                <ul>
                                    <li><kbd data-command="moveNoteUp">not set</kbd>, <kbd data-command="moveNoteDown">not set</kbd> - 在笔记列表中向上/向下移动笔记</li>
                                    <li><kbd data-command="moveNoteUpInHierarchy">not set</kbd>, <kbd data-command="moveNoteDownInHierarchy">not set</kbd> - 在层次结构中向上移动笔记</li>
                                    <li><kbd data-command="addNoteAboveToSelection">not set</kbd>, <kbd data-command="addNoteBelowToSelection">not set</kbd> - 多选上方/下方笔记</li>
                                    <li><kbd data-command="selectAllNotesInParent">not set</kbd> - 选择当前级别的所有笔记</li>
                                    <li><kbd>Shift+鼠标左键</kbd> - 选择笔记</li>
                                    <li><kbd data-command="copyNotesToClipboard">not set</kbd> - 将活动笔记(或当前选择)复制到剪贴板(用于 <a class="external" href="https://github.com/zadam/trilium/wiki/Cloning notes">克隆</a>)</li>
                                    <li><kbd data-command="cutNotesToClipboard">not set</kbd> - 将当前(或当前选择)笔记剪切到剪贴板中(用于移动笔记)</li>
                                    <li><kbd data-command="pasteNotesFromClipboard">not set</kbd> - 将笔记作为子笔记粘贴到活动笔记中(可以是移动还是克隆, 具体取决于它是被复制还是剪切到剪贴板中)</li>
                                    <li><kbd data-command="deleteNotes">not set</kbd> - 删除笔记/子树</li>
                                </ul>
                            </p>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">编辑笔记</h5>

                            <p class="card-text">
                                <ul>
                                    <li><kbd data-command="editNoteTitle">not set</kbd> 从笔记树切换到笔记标题, 或者从笔记标题切换到笔记内容.
                                        <kbd data-command="scrollToActiveNote">not set</kbd>将从编辑器切换回树状窗格.</li>
                                    <li><kbd>Ctrl+K</kbd> - 创建/编辑外部链接</li>
                                    <li><kbd data-command="addLinkToText">not set</kbd> - 创建内部链接</li>
                                    <li><kbd data-command="followLinkUnderCursor">not set</kbd> - 跟随链接</li>
                                    <li><kbd data-command="insertDateTimeToText">not set</kbd> - 在光标位置插入当前日期和时间</li>
                                    <li><kbd data-command="scrollToActiveNote">not set</kbd> - 跳到树状窗格并滚动到活动笔记</li>
                                </ul>
                            </p>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title"><a class="external" href="https://github.com/zadam/trilium/wiki/Text-notes#autoformat">像Markdown一样的自动格式化</a></h5>

                            <p class="card-text">
                                <ul>
                                    <li><kbd>##</kbd>, <kbd>###</kbd>, <kbd>####</kbd>等, 然后加上标题空间</li>
                                    <li><kbd>*</kbd>或者<kbd>-</kbd>后面接着项目符号列表的空位</li>
                                    <li><kbd>1.</kbd>或者<kbd>1)</kbd>后面接着编号列表的空位</li>
                                    <li>行首<kbd>&gt;</kbd>后面接着引用块的空位</li>
                                </ul>
                            </p>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">故障排除</h5>

                            <p class="card-text">
                                <ul>
                                    <li><kbd data-command="reloadFrontendApp">not set</kbd> - 重新加载Trilium前端</li>
                                    <li><kbd data-command="openDevTools">not set</kbd> - 显示开发人员工具</li>
                                    <li><kbd data-command="showSQLConsole">not set</kbd> - 显示SQL控制台</li>
                                </ul>
                            </p>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">其他</h5>

                            <p class="card-text">
                                <ul>
                                    <li><kbd data-command="quickSearch">not set</kbd> - 定位到快速搜索</li>
                                    <li><kbd data-command="findInText">not set</kbd> - 页内搜索</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>`;

export default class HelpDialog extends BasicWidget {
    doRender() {
        this.$widget = $(TPL);
    }

    showHelpEvent() {
        utils.openDialog(this.$widget);
    }
}
