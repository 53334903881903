import utils from "./utils.js";
import appContext from "../components/app_context.js";
import server from "./server.js";
import libraryLoader from "./library_loader.js";
import ws from "./ws.js";
import froca from "./froca.js";
import linkService from "./link.js";

function setupGlobs() {
    window.glob.isDesktop = utils.isDesktop;
    window.glob.isMobile = utils.isMobile;

    window.glob.getComponentByEl = el => appContext.getComponentByEl(el);
    window.glob.getHeaders = server.getHeaders;
    window.glob.getReferenceLinkTitle = href => linkService.getReferenceLinkTitle(href);
    window.glob.getReferenceLinkTitleSync = href => linkService.getReferenceLinkTitleSync(href);

    // required for ESLint plugin and CKEditor
    window.glob.getActiveContextNote = () => appContext.tabManager.getActiveContextNote();
    window.glob.requireLibrary = libraryLoader.requireLibrary;
    window.glob.ESLINT = libraryLoader.ESLINT;
    window.glob.appContext = appContext; // for debugging
    window.glob.froca = froca;
    window.glob.treeCache = froca; // compatibility for CKEditor builds for a while

    // for CKEditor integration (button on block toolbar)
    window.glob.importMarkdownInline = async () => appContext.triggerCommand("importMarkdownInline");

    window.glob.SEARCH_HELP_TEXT = `
    <strong>搜索建议</strong> - 参见 <button class="btn btn-sm" type="button" data-help-page="Search">搜索语法完整帮助文档</button>
    <p>
    <ul>
        <li>输入任意文字进行全文搜索</li>
                <li><code>#abc</code> - 会返回含有abc标签的笔记</li>
                <li><code>#year = 2019</code> - <code>year</code>并且值是<code>2019</code>的笔记</li>
                <li><code>#rock #pop</code> - 会匹配同时含有 <code>rock</code> 和  <code>pop</code> 标签的笔记</li>
                <li><code>#rock or #pop</code> - 含有其中任意一个标签的笔记</li>
                <li><code>#year &lt;= 2000</code> - 可以用比较符号(比如 &gt;, &gt;=, &lt;).</li>
                <li><code>note.dateCreated >= MONTH-1</code> - 上个月创建的笔记</li>
        <li><code>=handler</code> - 会执行<code>=handler</code>关联的代码来获取结果</li>
    </ul>
    </p>`;

    window.onerror = function (msg, url, lineNo, columnNo, error) {
        const string = msg.toLowerCase();

        let message = "未捕获的错误: ";

        if (string.includes("script error")) {
            message += '没有详细信息';
        } else {
            message += [
                `信息: ${msg}`,
                `URL: ${url}`,
                `行数: ${lineNo}`,
                `列数: ${columnNo}`,
                `错误对象: ${JSON.stringify(error)}`,
                `堆栈: ${error && error.stack}`
            ].join(', ');
        }

        ws.logError(message);

        return false;
    };

    for (const appCssNoteId of glob.appCssNoteIds || []) {
        libraryLoader.requireCss(`api/notes/download/${appCssNoteId}`, false);
    }

    utils.initHelpButtons($(window));

    $("body").on("click", "a.external", function () {
        window.open($(this).attr("href"), '_blank');

        return false;
    });
}

export default {
    setupGlobs
}
