import server from "../../../services/server.js";
import dialogService from "../../../services/dialog.js";
import toastService from "../../../services/toast.js";
import OptionsWidget from "./options_widget.js";

const TPL = `
<div class="options-section">
    <h4>ETAPI</h4>
    
    <p>ETAPI 是一个 REST API, 用于以编程方式访问 Trilium 实例, 无需 UI. <br/>
       更多信息请访问 <a href="https://github.com/zadam/trilium/wiki/ETAPI">wiki</a> 和 <a onclick="window.open('etapi/etapi.openapi.yaml')" href="etapi/etapi.openapi.yaml">ETAPI OpenAPI 文档</a>.</p>
    
    <button type="button" class="create-etapi-token btn btn-sm">创建新的 ETAPI 令牌</button>

    <h5>已有令牌</h5>
    
    <div class="no-tokens-yet">暂无令牌. 点击上方按钮来创建令牌.</div>
    
    <div style="overflow: auto; height: 500px;">
        <table class="tokens-table table table-stripped">
        <thead>
            <tr>
                <th>令牌名称</th>
                <th>创建</th>
                <th>动作</th>
            </tr>
        </thead>
        <tbody></tbody>
        </table>
    </div>
</div>

<style>
    .token-table-button {
        display: inline-block;
        cursor: pointer;
        padding: 3px;
        margin-right: 20px;
        font-size: large;
        border: 1px solid transparent;
        border-radius: var(--button-border-radius);
    }
    
    .token-table-button:hover {
        border: 1px solid var(--button-border-color);
    }
</style>`;

export default class EtapiOptions extends OptionsWidget {
    doRender() {
        this.$widget = $(TPL);

        this.$widget.find(".create-etapi-token").on("click", async () => {
            const tokenName = await dialogService.prompt({
                title: "新的 ETAPI 令牌",
                message: "请输入新令牌的名称",
                defaultValue: "新令牌"
            });

            if (!tokenName.trim()) {
                toastService.showError("令牌名称不能为空");
                return;
            }

            const {authToken} = await server.post('etapi-tokens', {tokenName});

            await dialogService.prompt({
                title: "ETAPI 令牌已创建",
                message: '复制新令牌到剪贴板. Trilium会保存令牌的哈希值, 请注意这将是你最后一次看到这个令牌.',
                defaultValue: authToken
            });

            this.refreshTokens();
        });

        this.refreshTokens();
    }

    async refreshTokens() {
        const $noTokensYet = this.$widget.find(".no-tokens-yet");
        const $tokensTable = this.$widget.find(".tokens-table");

        const tokens = await server.get('etapi-tokens');

        $noTokensYet.toggle(tokens.length === 0);
        $tokensTable.toggle(tokens.length > 0);

        const $tokensTableBody = $tokensTable.find("tbody");
        $tokensTableBody.empty();

        for (const token of tokens) {
            $tokensTableBody.append(
                $("<tr>")
                    .append($("<td>").text(token.name))
                    .append($("<td>").text(token.utcDateCreated))
                    .append($("<td>").append(
                        $('<span class="bx bx-pen token-table-button" title="重命名此令牌"></span>')
                            .on("click", () => this.renameToken(token.etapiTokenId, token.name)),
                        $('<span class="bx bx-trash token-table-button" title="删除/停用此令牌"></span>')
                            .on("click", () => this.deleteToken(token.etapiTokenId, token.name))
                    ))
            );
        }
    }

    async renameToken(etapiTokenId, oldName) {
        const tokenName = await dialogService.prompt({
            title: "重命名令牌",
            message: "请输入新令牌的名称",
            defaultValue: oldName
        });

        if (!tokenName?.trim()) {
            return;
        }

        await server.patch(`etapi-tokens/${etapiTokenId}`, {name: tokenName});

        this.refreshTokens();
    }

    async deleteToken(etapiTokenId, name) {
        if (!await dialogService.confirm(`你确认要删除ETAPI令牌 "${name}"?`)) {
            return;
        }

        await server.remove(`etapi-tokens/${etapiTokenId}`);

        this.refreshTokens();
    }
}
