import OptionsWidget from "../options_widget.js";

const TPL = `
<div class="options-section">
    <h4>功能选项组件</h4>
    <label>
        <input type="checkbox" class="promoted-attributes-open-in-ribbon">
        如果笔记中存在升级属性，升级属性功能选项卡将自动打开
    </label>
    
    <label>
        <input type="checkbox" class="edited-notes-open-in-ribbon">
        日记笔记自动打开编辑过的笔记选项
    </label>
</div>`;

export default class RibbonOptions extends OptionsWidget {
    doRender() {
        this.$widget = $(TPL);

        this.$promotedAttributesOpenInRibbon = this.$widget.find(".promoted-attributes-open-in-ribbon");
        this.$promotedAttributesOpenInRibbon.on('change', () =>
            this.updateCheckboxOption('promotedAttributesOpenInRibbon', this.$promotedAttributesOpenInRibbon));

        this.$editedNotesOpenInRibbon = this.$widget.find(".edited-notes-open-in-ribbon");
        this.$editedNotesOpenInRibbon.on('change', () =>
            this.updateCheckboxOption('editedNotesOpenInRibbon', this.$editedNotesOpenInRibbon));
    }

    async optionsLoaded(options) {
        this.setCheckboxState(this.$promotedAttributesOpenInRibbon, options.promotedAttributesOpenInRibbon);
        this.setCheckboxState(this.$editedNotesOpenInRibbon, options.editedNotesOpenInRibbon);
    }
}
