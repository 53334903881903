import utils from "../../services/utils.js";
import BasicWidget from "../basic_widget.js";

const TPL = `
<div class="password-not-set-dialog modal fade mx-auto" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mr-auto">未设置密码</h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="margin-left: 0;">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                受保护的笔记使用用户密码进行加密，但是目前没有设置密码.

                要保护笔记, 请<a class="open-password-options-button" href="javascript:">点击这里打开设置界面</a>并设置密码.
            </div>
        </div>
    </div>
</div>
`;

export default class PasswordNoteSetDialog extends BasicWidget {
    doRender() {
        this.$widget = $(TPL);
        this.$openPasswordOptionsButton = this.$widget.find(".open-password-options-button");
        this.$openPasswordOptionsButton.on("click", () => {
            this.triggerCommand("showOptions", { section: '_optionsPassword' });
        });
    }

    showPasswordNotSetEvent() {
        utils.openDialog(this.$widget);
    }
}
