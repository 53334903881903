import utils from '../../services/utils.js';
import treeService from "../../services/tree.js";
import importService from "../../services/import.js";
import options from "../../services/options.js";
import BasicWidget from "../basic_widget.js";

const TPL = `
<div class="import-dialog modal fade mx-auto" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">导入到笔记</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form class="import-form">
                <div class="modal-body">
                    <div class="form-group">
                        <label for="import-file-upload-input"><strong>选择导入文件</strong></label>

                        <input type="file" class="import-file-upload-input form-control-file" multiple />

                        <p>选中的文件内容将作为子笔记导入<strong class="import-note-title"></strong>.
                    </div>

                    <div class="form-group">
                        <strong>选项:</strong>

                        <div class="checkbox">
                            <label data-toggle="tooltip" title="Trilium <code> .zip </ code>导出文件可以包含可执行脚本, 这些脚本可能包含有害行为. 安全导入会取消所有导入的脚本的执行. 只有在您完全确认脚本安全的情况下取消勾选 &quot;安全导入&quot;.">
                                <input class="safe-import-checkbox" value="1" type="checkbox" checked>
                                <span>安全导入</span>
                            </label>
                        </div>

                        <div class="checkbox">
                            <label data-toggle="tooltip" title="如果选中此选项, 则Trilium将读取<code> .zip </ code>, <code> .enex </ code>和<code> .opml </ code>文件, 并从这些存档中的文件创建笔记.">
                                <input class="explode-archives-checkbox" value="1" type="checkbox" checked>
                                <span>读取 <code>.zip</code>, <code>.enex</code> 和 <code>.opml</code> 文件的内容.</span>
                            </label>
                        </div>

                        <div class="checkbox">
                            <label data-toggle="tooltip" title="<p>如果您选中此选项, Trilium将尝试通过缩放和优化来压缩导入的图像, 这可能会影响您看到的图像质量.</p><p>这不适用于<code>.zip</code>假定这些文件已经过优化, 使用元数据导入.</p>">
                                <input class="shrink-images-checkbox" value="1" type="checkbox" checked> <span>压缩图像</span>
                            </label>
                        </div>

                        <div class="checkbox">
                            <label>
                                <input class="text-imported-as-text-checkbox" value="1" type="checkbox" checked>

                                如果无法从笔记的元数据识别类型, 则将HTML, Markdown 和 TXT导入为文本笔记
                            </label>
                        </div>

                        <div class="checkbox">
                            <label>
                                <input class="code-imported-as-code-checkbox" value="1" type="checkbox" checked> 如果无法从笔记的元数据识别类型, 则将导入可识别的代码文件(例如 <code>.json</code>) 为代码笔记
                            </label>
                        </div>

                        <div class="checkbox">
                            <label>
                                <input class="replace-underscores-with-spaces-checkbox" value="1" type="checkbox" checked>

                                将笔记名称中的下划线替换为空格
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="import-button btn btn-primary">导入</button>
                </div>
            </form>
        </div>
    </div>
</div>`;

export default class ImportDialog extends BasicWidget {
    constructor() {
        super();

        this.parentNoteId = null;
    }

    doRender() {
        this.$widget = $(TPL);
        this.$form = this.$widget.find(".import-form");
        this.$noteTitle = this.$widget.find(".import-note-title");
        this.$fileUploadInput = this.$widget.find(".import-file-upload-input");
        this.$importButton = this.$widget.find(".import-button");
        this.$safeImportCheckbox = this.$widget.find(".safe-import-checkbox");
        this.$shrinkImagesCheckbox = this.$widget.find(".shrink-images-checkbox");
        this.$textImportedAsTextCheckbox = this.$widget.find(".text-imported-as-text-checkbox");
        this.$codeImportedAsCodeCheckbox = this.$widget.find(".code-imported-as-code-checkbox");
        this.$explodeArchivesCheckbox = this.$widget.find(".explode-archives-checkbox");
        this.$replaceUnderscoresWithSpacesCheckbox = this.$widget.find(".replace-underscores-with-spaces-checkbox");

        this.$form.on('submit', () => {
            // disabling so that import is not triggered again.
            this.$importButton.attr("disabled", "disabled");

            this.importIntoNote(this.parentNoteId);

            return false;
        });

        this.$fileUploadInput.on('change', () => {
            if (this.$fileUploadInput.val()) {
                this.$importButton.removeAttr("disabled");
            }
            else {
                this.$importButton.attr("disabled", "disabled");
            }
        });

        this.$widget.find('[data-toggle="tooltip"]').tooltip({
            html: true
        });
    }

    async showImportDialogEvent({noteId}) {
        this.parentNoteId = noteId;

        this.$fileUploadInput.val('').trigger('change'); // to trigger Import button disabling listener below

        this.$safeImportCheckbox.prop("checked", true);
        this.$shrinkImagesCheckbox.prop("checked", options.is('compressImages'));
        this.$textImportedAsTextCheckbox.prop("checked", true);
        this.$codeImportedAsCodeCheckbox.prop("checked", true);
        this.$explodeArchivesCheckbox.prop("checked", true);
        this.$replaceUnderscoresWithSpacesCheckbox.prop("checked", true);

        this.$noteTitle.text(await treeService.getNoteTitle(this.parentNoteId));

        utils.openDialog(this.$widget);
    }

    async importIntoNote(parentNoteId) {
        const files = Array.from(this.$fileUploadInput[0].files); // shallow copy since we're resetting the upload button below

        const boolToString = $el => $el.is(":checked") ? "true" : "false";

        const options = {
            safeImport: boolToString(this.$safeImportCheckbox),
            shrinkImages: boolToString(this.$shrinkImagesCheckbox),
            textImportedAsText: boolToString(this.$textImportedAsTextCheckbox),
            codeImportedAsCode: boolToString(this.$codeImportedAsCodeCheckbox),
            explodeArchives: boolToString(this.$explodeArchivesCheckbox),
            replaceUnderscoresWithSpaces: boolToString(this.$replaceUnderscoresWithSpacesCheckbox)
        };

        this.$widget.modal('hide');

        await importService.uploadFiles('notes', parentNoteId, files, options);
    }
}
