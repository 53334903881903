import server from "../../../services/server.js";
import toastService from "../../../services/toast.js";
import OptionsWidget from "./options_widget.js";

const TPL = `
<div class="options-section">
    <h4>自动备份</h4>
    
    <p>Trilium 可以自动备份数据库:</p>
    
    <ul style="list-style: none">
        <li>
            <label>
                <input type="checkbox" class="daily-backup-enabled">
                启用每日备份
            </label>
        </li>
        <li>    
            <label>
                <input type="checkbox" class="weekly-backup-enabled">
                启用每周备份
            </label>
        </li>
        <li>
        <label>
            <input type="checkbox" class="monthly-backup-enabled">
            启用每月备份
            </label>
        </li>
    </ul>
    
    <p>建议打开备份功能, 但这可能会使大型数据库和/或慢速存储设备的应用程序启动变慢.</p>
</div>

<div class="options-section">
    <h4>立即备份</h4>
    
    <button class="backup-database-button btn">立即备份数据库</button>
</div>

<div class="options-section">
    <h4>已有备份</h4>
    
    <ul class="existing-backup-list"></ul>
</div>
`;

export default class BackupOptions extends OptionsWidget {
    doRender() {
        this.$widget = $(TPL);

        this.$backupDatabaseButton = this.$widget.find(".backup-database-button");

        this.$backupDatabaseButton.on('click', async () => {
            const {backupFile} = await server.post('database/backup-database');

            toastService.showMessage(`数据库已备份至${backupFile}`, 10000);

            this.refresh();
        });

        this.$dailyBackupEnabled = this.$widget.find(".daily-backup-enabled");
        this.$weeklyBackupEnabled = this.$widget.find(".weekly-backup-enabled");
        this.$monthlyBackupEnabled = this.$widget.find(".monthly-backup-enabled");

        this.$dailyBackupEnabled.on('change', () =>
            this.updateCheckboxOption('dailyBackupEnabled', this.$dailyBackupEnabled));

        this.$weeklyBackupEnabled.on('change', () =>
            this.updateCheckboxOption('weeklyBackupEnabled', this.$weeklyBackupEnabled));

        this.$monthlyBackupEnabled.on('change', () =>
            this.updateCheckboxOption('monthlyBackupEnabled', this.$monthlyBackupEnabled));

        this.$existingBackupList = this.$widget.find(".existing-backup-list");
    }

    optionsLoaded(options) {
        this.setCheckboxState(this.$dailyBackupEnabled, options.dailyBackupEnabled);
        this.setCheckboxState(this.$weeklyBackupEnabled, options.weeklyBackupEnabled);
        this.setCheckboxState(this.$monthlyBackupEnabled, options.monthlyBackupEnabled);

        server.get("database/backups").then(backupFiles => {
            this.$existingBackupList.empty();

            if (!backupFiles.length) {
                backupFiles = [{filePath: "还没有备份", mtime: ''}];
            }

            for (const {filePath, mtime} of backupFiles) {
                this.$existingBackupList.append($("<li>").text(`${filePath} ${mtime ? ` - ${mtime}` : ''}`));
            }
        });
    }
}
