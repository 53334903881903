import AbstractSearchOption from "./abstract_search_option.js";
import SpacedUpdate from "../../services/spaced_update.js";
import server from "../../services/server.js";
import shortcutService from "../../services/shortcuts.js";
import appContext from "../../components/app_context.js";

const TPL = `
<tr>
    <td class="title-column">搜索字符串:</td>
    <td>
        <textarea class="form-control search-string" placeholder="全文搜索关键字, #标签=值 ..."></textarea>
    </td>
    <td class="button-column">
        <div class="dropdown help-dropdown">
          <span class="bx bx-help-circle icon-action" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></span>
          <div class="dropdown-menu dropdown-menu-right p-4">
            <strong>搜索语法</strong> - 参见 <button class="btn btn-sm" type="button" data-help-page="Search">搜索语法完整帮助文档</button>
            <p>
            <ul>
                <li>输入任意文字进行全文搜索</li>
                <li><code>#abc</code> - 会返回含有abc标签的笔记</li>
                <li><code>#year = 2019</code> - <code>year</code>并且值是<code>2019</code>的笔记</li>
                <li><code>#rock #pop</code> - 会匹配同时含有 <code>rock</code> 和  <code>pop</code> 标签的笔记</li>
                <li><code>#rock or #pop</code> - 含有其中任意一个标签的笔记</li>
                <li><code>#year &lt;= 2000</code> - 可以用比较符号(比如 &gt;, &gt;=, &lt;).</li>
                <li><code>note.dateCreated >= MONTH-1</code> - 上个月创建的笔记</li>
            </ul>
            </p>
          </div>
        </div>
        
        <span class="bx bx-x icon-action search-option-del"></span>
    </td>
</tr>`;

export default class SearchString extends AbstractSearchOption {
    static get optionName() { return "searchString" };
    static get attributeType() { return "label" };

    static async create(noteId) {
        await AbstractSearchOption.setAttribute(noteId, 'label', 'searchString');
    }

    doRender() {
        const $option = $(TPL);
        this.$searchString = $option.find('.search-string');
        this.$searchString.on('input', () => this.spacedUpdate.scheduleUpdate());

        shortcutService.bindElShortcut(this.$searchString, 'return', async () => {
            // this also in effect disallows new lines in query string.
            // on one hand, this makes sense since search string is a label
            // on the other hand, it could be nice for structuring long search string. It's probably a niche case though.
            await this.spacedUpdate.updateNowIfNecessary();

            this.triggerCommand('refreshResults');
        });

        this.spacedUpdate = new SpacedUpdate(async () => {
            const searchString = this.$searchString.val();
            appContext.lastSearchString = searchString;

            await this.setAttribute('label', 'searchString', searchString);

            if (this.note.title.startsWith('Search:') || this.note.title.startsWith('搜索:')) {
                await server.put(`notes/${this.note.noteId}/title`, {
                    title: `Search: ${searchString.length < 30 ? searchString : `${searchString.substr(0, 30)}…`}`
                });
            }
        }, 1000);

        this.$searchString.val(this.note.getLabelValue('searchString'));

        return $option;
    }

    showSearchErrorEvent({error}) {
        this.$searchString.tooltip({
            trigger: 'manual',
            title: `Search error: ${error}`,
            placement: 'bottom'
        });

        this.$searchString.tooltip("show");

        setTimeout(() => this.$searchString.tooltip("dispose"), 4000);
    }

    focusOnSearchDefinitionEvent() {
        this.$searchString.val(this.$searchString.val().trim() || appContext.lastSearchString).focus().select();
        this.spacedUpdate.scheduleUpdate();
    }
}
