import SpacedUpdate from "../../../services/spaced_update.js";
import AbstractBulkAction from "../abstract_bulk_action.js";
import noteAutocompleteService from "../../../services/note_autocomplete.js";

const TPL = `
<tr>
    <td colspan="2">
        <div style="display: flex; align-items: center">
            <div style="margin-right: 10px;" class="text-nowrap">移动笔记</div> 
                            
            <div style="margin-right: 10px;" class="text-nowrap">到</div>
            
            <div class="input-group">
                <input type="text" class="form-control target-parent-note" placeholder="目标父笔记"/>
            </div>
        </div>
    </td>
    <td class="button-column">
        <div class="dropdown help-dropdown">
            <span class="bx bx-help-circle icon-action" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></span>
            <div class="dropdown-menu dropdown-menu-right p-4">
                <p>对于所有匹配的笔记:</p>
                
                <ul>
                    <li>如果笔记只有一个父级, 则将笔记移动到新父级(旧笔记删除, 新笔记被创建到新的父级笔记下)</li>
                    <li>如果笔记有多个克隆, 则将笔记克隆到新父级(因为不清楚应该删除哪个)</li>
                    <li>如果无法将笔记移动到目标笔记, 什么也不做(防止形成循环树)</li>
                </ul>
            </div> 
        </div>
    
        <span class="bx bx-x icon-action action-conf-del"></span>
    </td>
</tr>`;

export default class MoveNoteBulkAction extends AbstractBulkAction {
    static get actionName() { return "moveNote"; }
    static get actionTitle() { return "移动笔记"; }

    doRender() {
        const $action = $(TPL);

        const $targetParentNote = $action.find('.target-parent-note');
        noteAutocompleteService.initNoteAutocomplete($targetParentNote);
        $targetParentNote.setNote(this.actionDef.targetParentNoteId);

        $targetParentNote.on('autocomplete:closed', () => spacedUpdate.scheduleUpdate());

        const spacedUpdate = new SpacedUpdate(async () => {
            await this.saveAction({
                targetParentNoteId: $targetParentNote.getSelectedNoteId()
            });
        }, 1000)

        $targetParentNote.on('input', () => spacedUpdate.scheduleUpdate());

        return $action;
    }
}
