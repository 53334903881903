import OptionsWidget from "../options_widget.js";
import server from "../../../../services/server.js";
import toastService from "../../../../services/toast.js";

const TPL = `
<div class="options-section">
    <h4>附件清理超时</h4>

    <p>如果附件在一段时间后不再被笔记引用，它们将自动被删除(并被清理)。</p>

    <div class="form-group">
        <label>在附件在笔记中未被使用 X 秒后清理附件</label>
        <input class="erase-unused-attachments-after-time-in-seconds form-control options-number-input" type="number" min="0">
    </div>
    
    <p>您也可以手动触发清理（而不考虑上述定义的超时时间）:</p>
    
    <button class="erase-unused-attachments-now-button btn">立即清理未使用的附件</button>
</div>`;

export default class AttachmentErasureTimeoutOptions extends OptionsWidget {
    doRender() {
        this.$widget = $(TPL);
        this.$eraseUnusedAttachmentsAfterTimeInSeconds = this.$widget.find(".erase-unused-attachments-after-time-in-seconds");
        this.$eraseUnusedAttachmentsAfterTimeInSeconds.on('change', () => this.updateOption('eraseUnusedAttachmentsAfterSeconds', this.$eraseUnusedAttachmentsAfterTimeInSeconds.val()));

        this.$eraseUnusedAttachmentsNowButton = this.$widget.find(".erase-unused-attachments-now-button");
        this.$eraseUnusedAttachmentsNowButton.on('click', () => {
            server.post('notes/erase-unused-attachments-now').then(() => {
                toastService.showMessage("未使用的附件已被删除.");
            });
        });
    }

    async optionsLoaded(options) {
        this.$eraseUnusedAttachmentsAfterTimeInSeconds.val(options.eraseUnusedAttachmentsAfterSeconds);
    }
}
